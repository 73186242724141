import React from 'react';
import posed from "react-pose";


const Button = posed.div(({ changes, speed, isHoverable }) => {
  const init = {};
  const hover = {};

  changes.forEach(change => {
    init[change.style] = change.init;
    hover[change.style] = change.hover;
  });

  return ({
    hoverable: isHoverable(),
    init: {
      transition: { duration: speed },
      ...init,
    },
    hover: {
      transition: { duration: speed },
      ...hover,
    },
  })
});

export default ({ changes = [], speed = 300, isHoverable = () => true, children, ...props }) => {
  return (
    <Button changes={changes} isHoverable={isHoverable} {...props}>
      {children}
    </Button>
  )
}