import React, { useContext, useState, useRef } from "react"
import ReCAPTCHA from "react-google-recaptcha"
import { 
  Container, Grid, Box,
  FormControl, TextField,
  InputLabel, Input, Button,
  Snackbar, makeStyles
} from "@material-ui/core"
import { Alert, AlertTitle } from '@material-ui/lab';
import { postConsultation } from "../../api/apiRequest"
import GenericTranslatesContext from "../wrappers/contexts/GenericTranslatesContext"
import { checkRoomNumber } from "../utils/checkRoomNumber"

// const REACT_APP_API_URL_LIGHT = process.env.REACT_APP_API_URL_LIGHT

const useStyles = makeStyles(() => ({
  // bannerImg: {
  //   backgroundPosition: "center center", 
  //   backgroundSize: "cover",
  //   backgroundRepeat: "no-repeat",
  //   marginTop: "60px",
  //   flexGrow: 1,
  //   display: "flex",
  //   padding: 30,
  //   alignItems: "center",
  //   justifyContent: "center"
  // },
  alert: {
    position: "fixed", 
    top: "90px", 
    zIndex: 20
  }
}))


export default () => {
  const classes = useStyles();
  const { getTranslate } = useContext(GenericTranslatesContext)

  const [consultation, setConsultation] = useState({
    name: "",
    email: "",
    phone: "",
    room: "",
    text: ""
  })
  const [disabled, setDisabled] = useState(true);
  const [alert, setAlert] = useState(false);
  const recaptchaRef = useRef();

  const handleInputChange = (event) => {
    setConsultation({
      ...consultation,
      [event.target.name]: event.target.value
    })
  };

  const handleCapcha = () => {
    if(recaptchaRef.current.getValue()){
      setDisabled(false)
    }
  }

  const handleClose = (reason) => {
    if (reason === 'clickaway') return;
    setAlert(false);
  };

  
  const getReservationObject = () => {
    const { name, email, phone, room, text } = consultation;
    let putJson = {
      name: name, 
      email: email,
      room: room,
      consultation: text,
      read: false
    }
    if(phone){putJson["phone"] = phone}
    
    return putJson;
  }

  const submitData = (event) => {
    event.preventDefault();

    if(!checkRoomNumber(consultation.room)){
      setAlert("roomAlert")
      return;
    }

    postConsultation(getReservationObject())
      .then(() => {
        setDisabled(true);
        setAlert("success");
        recaptchaRef.current.reset();
      })
      .catch(() => console.log("ERRRRRROR"))
  }


  return (
    <React.Fragment>
      <Snackbar autoHideDuration={600} style={{ height: "100vh", width: "100%" }} 
                open={alert} onClose={(reason) => handleClose(reason)}>
                  
        {alert === "success" ?
          <Alert variant="filled" severity="success" onClose={(reason) => handleClose(reason)} className={classes.alert}>
            {getTranslate("Consulta enviada correctamente")}!
          </Alert>
        :
        alert === "roomAlert" ?
          <Alert variant="filled" severity="error" onClose={(reason) => handleClose(reason)} className={classes.alert}>
            <AlertTitle>{getTranslate("Número de habitación no válido")}!</AlertTitle>
            {getTranslate("Por favor, introduzca un número de habitación distinto")}
          </Alert>
        : null
        }
        
      </Snackbar>


      {/* <div className={classes.bannerImg + " banner"} 
      // style={{ backgroundImage: `url(${REACT_APP_API_URL_LIGHT}${menu.banner.url})` }}
      >
        <Box maxWidth={"lg"} my={{xs: 4, sm: 6}} style={{ textAlign: "center" }}
            className="titleMargin-0 titleContrast linkContrast" component="section">
          <h1>{getTranslate("Hacer una consulta")}</h1>
        </Box>
      </div> */}

      <Container maxWidth={false} style={{ marginBottom: "50px", padding: "100px 30px 50px", maxWidth: 1100 }}>
        
        <h1 className="titleMargin-0 titleContrast linkContrast" style={{ textAlign: "center" }}>
          {getTranslate("Hacer una consulta")}
        </h1>

        <form onSubmit={submitData} autoComplete="off" style={{ width: "700px", maxWidth: "100%", margin: "0 auto"}}>
          <Grid container spacing={3}>
            <Grid item>
              <FormControl>
                <TextField name="name" label={getTranslate("Nombre")}
                            value={consultation.name} 
                            onChange={handleInputChange} 
                            required/>
              </FormControl>
            </Grid>
          
            <Grid item>  
              <FormControl>
                <InputLabel htmlFor="email" required>{getTranslate("Email")}</InputLabel>
                <Input name="email" type="email" 
                        required value={consultation.email} 
                        onChange={handleInputChange}/>
              </FormControl>
            </Grid>

            <Grid item>  
              <FormControl>
                <InputLabel htmlFor="phone">{getTranslate("Teléfono")}</InputLabel>
                <Input name="phone" type="tel" 
                        value={consultation.phone} 
                        onChange={handleInputChange}/>
              </FormControl>
            </Grid>

            <Grid item>
              <FormControl>
                <TextField name="room" value={consultation.room} 
                            label={getTranslate("Habitación")} 
                            required onChange={handleInputChange} />
              </FormControl>
            </Grid>

            <Grid xs={12} style={{ marginTop: 20 }}>
              <FormControl style={{ width: "100%" }}>
                <TextField name="text" label={getTranslate("Consulta")}
                            value={consultation.text} variant="filled"
                            onChange={handleInputChange} multiline rows={6} required/>
              </FormControl>
            </Grid>
          
          </Grid> 

          <Box display="flex" flexDirection="column">
            <ReCAPTCHA
              style={{transform: "scale(0.83)", transformOrigin:"80% 0", marginTop: 30, marginLeft: "auto", display: "flex"}}
              ref={recaptchaRef}
              sitekey="6Lf3j-QZAAAAAKRjWHbACgcpo-mR_AIpL7-XLL5q"
              onChange={handleCapcha}
            />
            <Button disabled={disabled} variant="contained" color="primary" type="submit" style={{display: "flex", marginLeft: "auto"}}>
              {getTranslate("Enviar")}
            </Button>
          </Box>

        </form>
      </Container>
    </React.Fragment>
  )
}

