import React, { useContext, useEffect, useState } from "react"
import { getOneOffer } from "../../api/apiRequest"
import Loading from "./Loading"
import { NavLink, Redirect } from "react-router-dom"
import { Button, Container } from "@material-ui/core"
import TextRich from "../dinamicComponents/TextRich"
import GenericTranslatesContext from "../wrappers/contexts/GenericTranslatesContext"
import FastGallery from "../dinamicComponents/FastGallery"


export default ({ match }) => {
  const { getTranslate } = useContext(GenericTranslatesContext)
  const [offer, setOffer] = useState(undefined)
  const id = match.params.id

  useEffect(() => {
    getOneOffer(match.params.id)
      .then(({ data }) => {
        if (data.length < 1) {
          return setOffer(null)
        }
        setOffer(data[0])
      })
      .catch(() => setOffer(null))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  if (offer === undefined) {
    return <Loading/>
  }
  if (!offer) {
    return <Redirect to="/"/>
  }

  const { img, contentPage, content, bookingUrl } = offer
  return (
    <React.Fragment>
      <div style={{ paddingTop: "100px" }}>

      </div>
      <FastGallery component={{ control: true, photos: img }} styleImg={{ backgroundPosition: "center" }}/>
      <Container maxWidth={false} style={{ marginBottom: "50px", marginTop: "50px" }}>
        <TextRich
          component={{ alignament: "left", translate: contentPage ? contentPage.translate : content.translate }}/>
      </Container>
      <Container maxWidth={"lg"} style={{ marginBottom: "50px" }}>
        <NavLink to="/offers" style={{ marginTop: "40px", marginBottom: "30px", marginRight: "10px" }}>
          <Button id="exception" color={"primary"} style={{ borderColor: "#8f7954" }}
                  className="textWhite" variant="outlined">
            <div style={{ color: "#8f7954" }}>
              {getTranslate("Volver")}
            </div>
          </Button>
        </NavLink>
        {bookingUrl && <a rel="noopener noreferrer" target="_blank" href={bookingUrl}
                          style={{ marginTop: "40px", marginBottom: "30px" }}>
          <Button id="exception" color={"primary"} style={{ borderColor: "#8f7954", minWidth: "150px" }}
                  className="textWhite" variant="contained">
            <div>
              {getTranslate("Reservar")}
            </div>
          </Button>
        </a>}
      </Container>
    </React.Fragment>
  )
}
