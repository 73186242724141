import React, { useContext } from "react"
import { Box, makeStyles } from "@material-ui/core"
import Toolbar from "@material-ui/core/Toolbar"
import RoomServiceIcon from "@material-ui/icons/RoomService"
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Hidden from "@material-ui/core/Hidden"
import GenericTranslatesContext from "../../wrappers/contexts/GenericTranslatesContext"
import GeneralDataContext from "../../wrappers/contexts/GeneralDataContext"
import constants from "../../../styles/constants"
import posed from "react-pose"
import LanguagesContext from "../../wrappers/contexts/LanguagesContext";

const Button = posed.div({
  hoverable: true,
  init: {
    bottom: 0,
  },
  hover: {
    bottom: 5,
  },
})

const useStyles = makeStyles(() => ({
  bookingBtn: {
    top: "auto",
    position: "fixed",
    left: 0,
    zIndex: 100,
    backgroundColor: constants.color.extremColor,
    width: '60%'
  },
  checkInBtn: {
    top: "auto",
    position: "fixed",
    right: 0,
    zIndex: 100,
    backgroundColor: constants.color.extremColor2,
    width: '40%'
  },
  icons: {
    height: "25px",
    width: "25px",
    color: constants.color.white,
    marginRight: "10px",
  },
  toolbar: {
    display: "flex",
    justifyContent: "center",
  },
  text: {
    color: constants.color.white,
    textDecoration: "none",
    textTransform: "uppercase",
    display: "flex",
    alignItems: "center",
  },
}))

export default () => {
  const { getTranslate } = useContext(GenericTranslatesContext)
  const { lang } = useContext(LanguagesContext);
  const contact = useContext(GeneralDataContext)
  const classes = useStyles()
  return (
    <Hidden mdUp>
      <Button position="fixed" className={classes.bookingBtn}>
        <a href={(contact.bookingUrl || "").replace("{{lang}}", lang || "es")}>
          <Toolbar className={classes.toolbar}>
            <Box className={classes.text}>
              <RoomServiceIcon className={classes.icons}/>
              <h5>
                <strong>
                  {getTranslate("Reservar")}
                </strong>
              </h5>
            </Box>
          </Toolbar>
        </a>
      </Button>
      <Button position="fixed" className={classes.checkInBtn}>
        <a href={contact.checkInUrl}>
          <Toolbar className={classes.toolbar}>
            <Box className={classes.text}>
              <LocationOnIcon className={classes.icons}/>
              <h5>
                <strong>
                  {getTranslate("Checkin")}
                </strong>
              </h5>
            </Box>
          </Toolbar>
        </a>
      </Button>
    </Hidden>
  )
};
