import React, { useContext, useState, useRef, useEffect } from "react"
import { Redirect } from "react-router-dom"
import ReCAPTCHA from "react-google-recaptcha"
import { addDays, addMinutes, format, setHours, setMinutes } from 'date-fns'
import { 
  Container, Grid, Box,
  FormControl, TextField,
  InputLabel, Input, Button,
  FormHelperText, makeStyles,
  Snackbar, Select, MenuItem
} from "@material-ui/core"
import { Alert, AlertTitle } from '@material-ui/lab';

import { postSpaAppointment } from "../../api/apiRequest"
import useSpaSettings from "../../components/hooks/requests/useSpaSettings"
import useSpaAppointments from "../../components/hooks/requests/useSpaAppointments"
import Loading from "./Loading"
import BannerImg from "../dinamicComponents/shared/banner/BannerImg"
import GenericTranslatesContext from "../wrappers/contexts/GenericTranslatesContext"
import { checkRoomNumber } from "../utils/checkRoomNumber"


import SpaDatePicker from "./spaReservation/SpaDatePicker"

const REACT_APP_API_URL_LIGHT = process.env.REACT_APP_API_URL_LIGHT

const useStyles = makeStyles(() => ({
  alert: {
    position: "fixed", 
    top: "90px", 
    zIndex: 20
  }
}))


export default () => {
  const classes = useStyles();
  const { getTranslate } = useContext(GenericTranslatesContext)
  const [appointment, setAppointment] = useState({
    name: "",
    email: "",
    room: "",
    numberPeople: 1,
    date: new Date()
  })
  const [disabled, setDisabled] = useState(true);
  const [alert, setAlert] = useState(false);
  const recaptchaRef = useRef();

  // Hooks
  const { settings } = useSpaSettings()
  const { appointments } = useSpaAppointments();

  useEffect(() => {
    if (settings !== undefined && settings) {
      setAppointment({
        ...appointment,
        date: getNextAbleHour()
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings])


  const getWeekdaySchedule = date => {
    switch (parseInt(format(date, "i"))) {
      case 1:
        return settings["Lunes"]
      case 2:
        return settings["Martes"]
      case 3:
        return settings["Miercoles"]
      case 4:
        return settings["Jueves"]
      case 5:
        return settings["Viernes"]
      case 6:
        return settings["Sabado"]
      case 7:
        return settings["Domingo"]
      default:
        return null
    }
  }

  const getTimeFromString = ( stringTime, date = new Date() ) => {
    const timeArray = stringTime.split(":")
    return setHours(setMinutes(date, timeArray[1]), timeArray[0])
  }

  const getNextAbleHour = (date = new Date()) => {
    const later = addMinutes(date, 60 + settings.defaultTime);
    if(later >= getTimeFromString(getWeekdaySchedule(date).ClosingHour)){
      let count = 1 
      while(count < 8){
        const newDate = setHours(setMinutes(addDays(date, count), 0), 0) 
        if(getWeekdaySchedule(newDate).opens) return newDate
        count ++
      }
      return date
    } else 
      return date
  }

  const handleInputChange = (event) => {
    setAppointment({
      ...appointment,
      [event.target.name]: event.target.value
    })
  };

  const handleCapcha = () => {
    if(recaptchaRef.current.getValue()){
      setDisabled(false)
    }
  }

  const handleClose = (reason) => {
    if (reason === 'clickaway') return;
    setAlert(false);
  };

  const getAppointmentObject = () => {
    const { name, email, room, numberPeople, date } = appointment;
    const endingDate = addMinutes(date, settings.defaultTime)
    let putJson = {
      ...{name, email, numberPeople},
      startingTime: format(date, 'yyyy-MM-dd') + "T" + format(date, 'HH:mm') + ":00+01:00",
      endingTime: format(endingDate, 'yyyy-MM-dd') + "T" + format(endingDate, 'HH:mm') + ":00+01:00",
    }
    if(room){putJson["room"] = room}
    return putJson;
  }

  const submitData = (event) => {
    event.preventDefault();
    
    if(format(appointment.date, 'yyyy-MM-dd HH:mm') === format(getNextAbleHour(), 'yyyy-MM-dd HH:mm')
      || format(appointment.date, 'HH:mm') === "00:00"){
      setAlert("dateAlert")
      return;
    }

    if(appointment.room && !checkRoomNumber(appointment.room)){
      setAlert("roomAlert")
      return;
    }

    postSpaAppointment(getAppointmentObject())
      .then(() => {
        setDisabled(true);
        setAlert("success");
        recaptchaRef.current.reset();
      })
      .catch(() => console.log("ERRRRRROR"))
  }


  if (settings === undefined || appointments === undefined) {
    return <Loading/>
  }

  if ((!settings)) {
    return <Redirect to="/"/>
  }


  return (
    <React.Fragment>

      <Snackbar autoHideDuration={600} style={{ height: "100vh", width: "100%" }} 
                open={alert} onClose={(reason) => handleClose(reason)}>
        {alert === "success" ?
          <Alert variant="filled" severity="success" onClose={(reason) => handleClose(reason)} className={classes.alert}>
            <AlertTitle>{getTranslate("Solicitud enviada correctamente")}!</AlertTitle>
            {getTranslate("Esté atento a su correo electrónico")}
          </Alert>
        :
        alert === "dateAlert" ?
          <Alert variant="filled" severity="error" onClose={(reason) => handleClose(reason)} className={classes.alert}>
            <AlertTitle>{getTranslate("Fecha no válida")}!</AlertTitle>
            {getTranslate("Por favor, elija una hora válida")}
          </Alert>
        : 
        alert === "roomAlert" ?
          <Alert variant="filled" severity="error" onClose={(reason) => handleClose(reason)} className={classes.alert}>
            <AlertTitle>{getTranslate("Número de habitación no válido")}!</AlertTitle>
            {getTranslate("Por favor, introduzca un número de habitación distinto")}
          </Alert>
        : null
        }
      </Snackbar>


      <Container maxWidth={false} style={{ marginBottom: "50px", padding: "140px 30px 50px", maxWidth: 1100 }}>
        <Box maxWidth={"lg"} mb={{xs: 5, sm: 8}} style={{ textAlign: "center" }}
            className="titleMargin-0 titleContrast linkContrast" component="section">
          <h1>{getTranslate("Solicitar una cita")}</h1>
          <p>{getTranslate("Pide cita para nuestro spa, la confirmaremos lo antes posible")}</p> 
          <p>{"(" + settings.defaultTime + " " + getTranslate("minutos por cita") + ")"}</p>
        </Box>


        <Grid container>
          <Box component={Grid} item xs={7} md={8} xl={9} display={{ xs: 'none', sm: 'block' }}>
            <BannerImg img={REACT_APP_API_URL_LIGHT + settings.sideImage.url} height={"400px"}
                        style={{ backgroundPosition: "center center", height: "100%" }} parallax={false}/>
          </Box>
          <Grid item xs={12} sm={5} md={4} xl={3}>
            <form onSubmit={submitData} autoComplete="off">
              <Box component={Grid} container pl={{ xs: 2, sm: 4 }} justify="flex-start" spacing={2}>
                <Grid item>
                  <FormControl>
                    <TextField name="name" label={getTranslate("Nombre")}
                                value={appointment.name} 
                                onChange={handleInputChange} 
                                required aria-describedby="nameHelper" />
                    <FormHelperText id="nameHelper">{getTranslate("Reserva a nombre de")}</FormHelperText>
                  </FormControl>
                </Grid>
              
                <Grid item>  
                  <FormControl>
                    <InputLabel htmlFor="email" required>{getTranslate("Email")}</InputLabel>
                    <Input name="email" type="email" 
                            required value={appointment.email} 
                            onChange={handleInputChange}/>
                  </FormControl>
                </Grid>

                <Grid item>
                  <FormControl>
                    <TextField name="room" label={getTranslate("Habitación")} aria-describedby="roomHelper" 
                            value={appointment.room} 
                            onChange={handleInputChange} />
                    <FormHelperText id="roomHelper">{getTranslate("Opcional")}</FormHelperText>
                  </FormControl>
                </Grid>
                
                <Grid item>
                  <FormControl>
                    <InputLabel id="numSelectLabel">{getTranslate("Número de personas")}</InputLabel>
                    <Select labelId="numSelectLabel" name="numberPeople" aria-describedby="numHelper" 
                      value={appointment.numberPeople} onChange={handleInputChange} style={{ minWidth: "160px" }}>
                      <MenuItem value={1}>1</MenuItem>
                      <MenuItem value={2}>2</MenuItem>
                    </Select>
                    <FormHelperText id="numHelper">Max.2</FormHelperText>
                  </FormControl>
                </Grid>

                <Grid item>
                  <SpaDatePicker 
                    selected={appointment.date} 
                    {...{ getWeekdaySchedule,
                      getNextAbleHour, appointments, 
                      getTimeFromString, settings,
                      handleInputChange }}/> 
                </Grid>
                
              </Box> 
                
              <Box display="flex" flexDirection="column">
                <ReCAPTCHA
                  style={{transform: "scale(0.83)", transformOrigin:"80% 0", marginTop: "20px", marginLeft: "auto", display: "flex"}}
                  ref={recaptchaRef}
                  sitekey="6Lf3j-QZAAAAAKRjWHbACgcpo-mR_AIpL7-XLL5q"
                  onChange={handleCapcha}
                />
                <Button disabled={disabled} variant="contained" color="primary" type="submit" style={{display: "flex", marginLeft: "auto"}}>
                  {getTranslate("Reservar")}
                </Button>
              </Box>
            </form>
          </Grid>
        </Grid> 

      </Container>
    </React.Fragment>
  )
}
