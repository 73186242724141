import React, { useContext, useEffect, memo } from 'react';
import { Box, CssBaseline, ThemeProvider } from '@material-ui/core';
import globalTheme from './styles/globalTheme';
import { Redirect, Route, Switch, useLocation } from "react-router";
import { BrowserRouter } from "react-router-dom";
// import ReactGA from 'react-ga4';

import LanguagesWrapper from "./components/wrappers/LanguagesWrapper";
import Nav from "./components/shared/nav/Nav"
import GenericTranslatesWrapper from "./components/wrappers/GenericTranslationsWrapper";
import GeneralDataWrapper from "./components/wrappers/GeneralDataWrapper";
import Footer from "./components/shared/footer/Footer";
import './App.css';
import BottomNav from "./components/shared/nav/BottomNav";
import Room from "./components/pages/Room";
import SectionsWrapper from "./components/wrappers/SectionsWrapper";
import SectionsContext from "./components/wrappers/contexts/SectionsContext";
import Section from "./components/pages/Section";
import Contact from "./components/pages/Contact";
import Cookies from "./components/shared/cookies/Cookies";
import CookiesWrapper from "./components/wrappers/CookiesWrapper";
import LoadingControlWrapper from "./components/wrappers/LoadingControlWrapper";
import LanguagesContext from "./components/wrappers/contexts/LanguagesContext";
import LoadingControlContext from "./components/wrappers/contexts/LoadingControlContext";
import Offer from "./components/pages/Offer"
import Offers from "./components/pages/Offers"
import Blog from "./components/pages/Blog"
import Article from "./components/pages/Article"
import SpaReservation from "./components/pages/SpaReservation"
import RestaurantReservation from "./components/pages/RestaurantReservation"
import Consultation from "./components/pages/Consultation"



// ReactGA.initialize('G-M61MMK4NXF');

export default () => {

  const MemoLanguagesWrapper = memo(LanguagesWrapper);
  return (
    <ThemeProvider theme={globalTheme}>
      <CssBaseline/>
      <LoadingControlWrapper>
        <CookiesWrapper>
          <MemoLanguagesWrapper>
            <GeneralDataWrapper>
              <GenericTranslatesWrapper>
                <SectionBlock/>
              </GenericTranslatesWrapper>
            </GeneralDataWrapper>
          </MemoLanguagesWrapper>
        </CookiesWrapper>
      </LoadingControlWrapper>
    </ThemeProvider>
  );
}

const SectionBlock = () => {
  const { lang } = useContext(LanguagesContext);
  return <BrowserRouter basename={lang}>
    <SectionsWrapper>
      <Router/>
    </SectionsWrapper>
  </BrowserRouter>
};

const Router = () => {
  const { sections } = useContext(SectionsContext);
  const { setLoading } = useContext(LoadingControlContext);
  setLoading(false);

  // useEffect(() => {
  //   const path = window.location.pathname + window.location.search
  //   ReactGA.send({ hitType: "pageview", page: path, title: path });
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [window.location.href]);
  
  return (
    <>
      <ScrollToTop/>
      <Nav/>
      <Box style={{ minHeight: "100vh" }} overflow="hidden">
        <Switch>
          <Route exact path="/offer/:id" component={Offer}/>
          <Route exact path="/room/:id" component={Room}/>
          <Route exact path="/offers" component={Offers}/>
          <Route exact path="/contact" component={Contact}/>
          <Route exact path="/restaurant" component={RestaurantReservation}/>
          <Route exact path="/spa" component={SpaReservation}/>
          <Route exact path="/blog" component={Blog}/>
          <Route exact path="/blog/article/:id" component={Article}/>
          <Route exact path="/consultation" component={Consultation}/>
          {sections.map((section, key) => <Route key={key} exact path={"/" + section.urlText} component={Section}/>)}
          <Redirect to="/"/>
        </Switch>
      </Box>
      <Footer/>
      <BottomNav/>
      <Cookies/>
    </>
  )
};

const ScrollToTop = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
};
