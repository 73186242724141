import React, { useContext, /*useState,*/ useEffect } from 'react';
import posed from "react-pose";
import { Button, makeStyles } from "@material-ui/core";

import { CookiesContext } from '../../wrappers/CookiesWrapper';
import ContactContext from "../../wrappers/contexts/GeneralDataContext";
import GenericTranslatesContext from "../../wrappers/contexts/GenericTranslatesContext";

import Translator from "../Translator";
import constants from "../../../styles/constants";

const TheBox = posed.div(() => {
  return ({
    visible: {
      opacity: 1,
      applyAtStart: { display: 'block' },
      transition: { duration: 700 }
    },
    invisible: {
      opacity: 0,
      applyAtEnd: { display: 'none' },
      transition: { duration: 700 }
    },
  })
});

const Modal = posed.div(() => {
  return ({
    visible: {
      height: "auto",
      applyAtStart: { display: 'block' },
      transition: { duration: 700 }
    },
    invisible: {
      height: 0,
      applyAtEnd: { display: 'none' },
      transition: { duration: 700 }
    },
  })
});

const useStyles = makeStyles(() => ({
  box: {
    backgroundColor: constants.color.white,
    boxShadow: "rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px",
    position: "fixed",
    maxWidth: "350px",
    width: "95%",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    padding: "0px 25px",
    overflow: "hidden"
  },
  background: {
    zIndex: 10000,
    position: "fixed",
    overflow: "hidden",
    top: 0,
    height: "100vh",
    width: "100%",
    background: "linear-gradient(180deg, rgba(255,255,255,0.3) 0%, rgba(255,255,255,0.3) 50%, rgba(255,255,255,1) 95%)"
  }
}));



export default () => {
  const { accepted, accept } = useContext(CookiesContext);
  const contact = useContext(ContactContext);
  // const [accepted, setAccepted] = useState(localStorage.getItem("cookies-Ynaira"));
  const classes = useStyles();
  const { getTranslate } = useContext(GenericTranslatesContext);

  useEffect(() => {
    if(!accepted){
      document.body.style.height = "100vh"
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.height = null
      document.body.style.overflow = null
    }
  }, [accepted])

  // const accept = () => {
  //   localStorage.setItem("cookies-Ynaira", false);
  //   setAccepted(true)
  // };

  return (
    <TheBox className={classes.background} pose={!accepted ? "visible" : "invisible"}>
      <Modal className={classes.box + " margin-5 cookies"} pose={!accepted ? "visible" : "invisible"}
              style={{ textAlign: contact.cookie ? contact.cookie.alignement : "center" }}>
        <div style={{ padding: "25px 0px" }}>
          {contact.cookie && <Translator translates={contact.cookie.translate}/>}
          <Button id="exception" color={"primary"} style={{ borderColor: "#8f7954", minWidth: "150px" }}
                  className="textWhite" variant="contained" fullWidth onClick={accept}>
            <div>
              {getTranslate("Aceptar")}
            </div>
          </Button>
        </div>
      </Modal>
    </TheBox>
  )
};
