import React, { useState } from "react"
import ModalContext from "./contexts/ModalContext"
import Modal from "../shared/modal/Modal"


export default ({ children }) => {
  const [content, changeContent] = useState({ body: null, height: "85vh" })

  const setContent = (body, height = "85vh") => {
    changeContent({ body, height })
  }

  return (
    <ModalContext.Provider value={{ setContent }}>
      <Modal changeModal={() => setContent(null)} children={content.body} height={content.height}/>
      {children}
    </ModalContext.Provider>
  )
};
