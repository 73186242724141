import React from "react"
import { FormControl, InputLabel, InputAdornment } from "@material-ui/core"
import EventIcon from '@material-ui/icons/Event';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es';

const CustomDatePicker = (props) => {
  const {
    title, curDate,
    handleOnChange,
    timeIntervals,
    minDate, maxDate,
    minTime, maxTime,
    filterTime, filterDate,
    label
  } = props

  return(
    <FormControl className="MuiTextField-root MuiFormControl-marginNormal">
      <InputLabel htmlFor="reservationDate" shrink required className="MuiFormLabel-filled">
        {title}
      </InputLabel>
      <div className="MuiInputBase-root MuiInput-root MuiInput-underline
                      MuiInputBase-formControl MuiInput-formControl 
                      MuiInputBase-adornedEnd">
        <DatePicker {...{ timeIntervals, minDate, maxDate, minTime, maxTime, filterTime, filterDate}}
          selected={curDate}
          onChange={handleOnChange}
          className="MuiInputBase-input MuiInput-input 
                    MuiInputBase-inputAdornedEnd"
          timeInputLabel={label}
          showTimeSelect
          fixedHeight
          withPortal
          locale={es}
          timeFormat="p"
          dateFormat="Pp"
          timeCaption={label}
          disabledKeyboardNavigation />

        <InputAdornment position="start">
          <EventIcon style={{ color: "#706F6E" }}/>
        </InputAdornment>
      </div>
    </FormControl>
  )
}

export default CustomDatePicker;