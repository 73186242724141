import React, { useState } from "react"

export const CookiesContext = React.createContext();

export default ({ children }) => {
  const [accepted, setAccepted] = useState(false)

  const acceptCookies = () => {
    setAccepted(true)
  }

  return (
    <CookiesContext.Provider value={{ accepted, accept: acceptCookies }}>
      {children}
    </CookiesContext.Provider>
  )
};
