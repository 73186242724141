import React, { useEffect, useState } from "react"
import { getArticlesNoContent } from "../../api/apiRequest"
import Loading from "./Loading"
import { NavLink, Redirect } from "react-router-dom"
import Moment from 'moment';
import Translator from "../shared/Translator"

import { Container, makeStyles } from "@material-ui/core"
import Grid from "@material-ui/core/Grid"
import Hidden from '@material-ui/core/Hidden';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from "@material-ui/core/Box";


const REACT_APP_API_URL_LIGHT = process.env.REACT_APP_API_URL_LIGHT

const useStyles = makeStyles((theme) => ({
  textBox: {
    padding: 20
  },
  title: {
    lineHeight: "1.1",
    fontWeight: 400,
    fontFamily: "PlayfairDisplay",
    marginBottom: 20,
    marginTop: 50
  },
  cardTitle: {
    fontSize: "24px",
    fontFamily: "LibreBaskerville",
    lineHeight: 1.2,
    color: "#b59a8a",
    fontStyle: "italic",
    paddingBottom: 20
  },
  line: {
    borderBottom: "solid 1px #8f7954",
    width: "60%",
    minWidth: "40px",
    // marginLeft: "10px",
  },
  cardImg: {
    minHeight: 200,
    backgroundPosition: "center center", 
    height: "100%",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    flexGrow: 1,
    display: "flex",
    padding: 30,
    alignItems: "center",
    justifyContent: "center", 
    position: "relative"
  },
  category: {
    background: "#fff",
    fontFamily: "LibreBaskerville",
    padding: "0 10px",
    lineHeight: 1.5,
    fontSize: "14px",
    color: "#333",
    borderRadius: "2px",
    maxWidth: "90%",
    position: "absolute",
    top: 30,
    right: 30,
    textTransform: "uppercase"
  }
}))

const theme = createMuiTheme();
theme.typography.h1 = {
  fontSize: "50px",
  [theme.breakpoints.up('sm')]: {
    fontSize: '90px'
  }
};

export default ({ match }) => {
  const classes = useStyles()
  const [articles, setArticles] = useState(undefined)
  const id = match.params.id

  useEffect(() => {
    getArticlesNoContent()
      .then(({ data }) => {
        if (data.length < 1) {
          return setArticles(null)
        }
        setArticles(data)
      })
      .catch(() => setArticles(null))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  if (articles === undefined) {
    return <Loading/>
  }
  if (!articles) {
    return <Redirect to="/"/>
  }

  let bigArticles = []
  for(let i in articles){
    if( i % 3 === 0){
      bigArticles.push(articles[i])
    }
  }

  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        <Container maxWidth={false} style={{ marginBottom: "50px", paddingTop: "100px", maxWidth: 1400 }}>
          <Typography variant="h1" className={classes.title}>Blog Ynaira Hotel</Typography>

          <Grid container>
            <Hidden smDown>
              <Grid item xs={6}>
                {bigArticles.map((article, key) => {return(
                  <Box p={1} key={key}>
                    <NavLink to={("/blog/article/" + article.url)}>
                      <div className={classes.cardImg} style={{ backgroundImage:"url(" + (REACT_APP_API_URL_LIGHT + article.image.url) + ")", minHeight: "400px" }}>
                        {article.category && <div className={classes.category}>
                          <Translator translates={article.category.name}/>
                        </div>}
                      </div>
                      <Box className={classes.textBox}>
                        <h2 className={classes.cardTitle}>
                          <Translator translates={article.title}/>
                        </h2>
                        <div className={classes.line}/>
                        <p style={{ fontFamily: "PlayfairDisplay", color: "#666666" }}>
                          <time>{Moment(article.publishedAt).format('dddd, DD MMM yyyy')}</time>
                        </p>
                      </Box>
                    </NavLink>
                  </Box>
                )})}
              </Grid>
            </Hidden>

            <Grid item xs={12} md={6}>
              <Grid container>
                {articles.map((article, key) => {return(
                  <Box key={key} component={Grid} item xs={12} sm={6} p={1} display={(key % 3 === 0) ? {md: "none"} : undefined}>
                    <NavLink key={key} to={("/blog/article/" + article.url)}>
                      <Box>
                        <div className={classes.cardImg + " banner"} style={{ backgroundImage:"url(" + (REACT_APP_API_URL_LIGHT + article.image.url) + ")" }}>
                          {article.category && <div className={classes.category}>
                            <Translator translates={article.category.name}/>
                          </div>}
                        </div>
                        <Box className={classes.textBox}>
                          <h2 className={classes.cardTitle}>
                            <Translator translates={article.title}/>
                          </h2>
                          <div className={classes.line}/>
                          <p style={{ fontFamily: "PlayfairDisplay", color: "#666666" }}>
                            <time>{Moment(article.publishedAt).format('dddd, DD MMMM yyyy')}</time>
                          </p>
                        </Box>
                      </Box>
                    </NavLink>
                  </Box>
                )})}
              </Grid>
            </Grid>
            
          </Grid>
        </Container>
      </ThemeProvider>
    </React.Fragment>
  )
}
