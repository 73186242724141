import React, { useState, useEffect, useContext } from 'react';
import ContactContext from "./contexts/GeneralDataContext";
import { getGeneralData } from "../../api/apiRequest";
import LoadingControlContext from "./contexts/LoadingControlContext";


export default ({ children }) => {
  const { setError } = useContext(LoadingControlContext);
  const generalData = localStorage.getItem("ynaira-general-new-data") ? JSON.parse(localStorage.getItem("ynaira-general-new-data")) : {};
  const [data, setData] = useState(generalData);

  const error = () => setError("No se han encontrado datos generales");
  useEffect(() => {
    getGeneralData().then(({ data }) => {
      if(data){
        setData(data)
        localStorage.setItem("ynaira-general-new-data", JSON.stringify(data))
      } else {
        error()
      }
    }).catch(() => error())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <ContactContext.Provider value={data} children={children}/>
};
