import { useState, useEffect } from "react"
import { getTodaysMenu } from "../../../api/apiRequest";

const useTodaysMenu = () => {
  const [menu, setMenu] = useState();

  useEffect(() => {
    getTodaysMenu()
      .then(({ data }) => {
        if (data.length < 1) return setMenu(null)
        setMenu(data);
      })
      .catch(() => setMenu(null))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  
  return {
    menu
  }
  
}

export default useTodaysMenu;