import React from 'react';
import Grid from "@material-ui/core/Grid";
import logoColor from "../../media/logo/smallLogoColor.svg"
import "./loading/loading.css"

export default () => {
  return (
    <Grid container justify="center" alignItems="center" style={{ minHeight: "100vh" }} className="textWhite">
      <div>
        <img width="200px" height="200px" src={logoColor} alt="logo Ynaira" className="preloader"/>
      </div>
    </Grid>
  )
}
