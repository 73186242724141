export default {
  color: {
    white: "#fff",
    gray100: "#f8f9fa",
    gray200: "#e9ecef",
    gray300: "#dee2e6",
    gray400: "#ced4da",
    gray500: "#adb5bd",
    gray600: "#6c757d",
    gray700: "#495057",
    gray800: "#343a40",
    gray900: "#212529",
    black: "#000",
    // background: "rgb(243,240,235)",
    background: "white",
    primary: "#8f7954",
    extremColor: "rgb(162,33,52)",
    extremColor2: "rgb(61,12,18)",
    extremColorOp: (op) => "rgba(162,33,52," + op + ")",
    primaryOp: (op) => "rgba(143,121,84," + op + ")",
    whiteOp: (op) => "rgba(255,255,255," + op + ")",
    backgroundOp: (op) => "rgba(243,240,235," + op + ")",
  },
  fontFamily: 'Montserrat',
};
