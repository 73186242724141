import React, { useContext, useEffect, useState } from "react"
import { Button, Container, Grid, makeStyles } from "@material-ui/core"
import BannerImg from "./shared/banner/BannerImg"
import constants from "../../styles/constants"
import Translator, { translatorSimple } from "../shared/Translator"
import GenericTranslatesContext from "../wrappers/contexts/GenericTranslatesContext"
import LanguagesContext from "../wrappers/contexts/LanguagesContext"
import MoveHoverTurbo from "../shared/move/MoveHoverTurbo"
import ModalContext from "../wrappers/contexts/ModalContext"
import cacheImage from "../utils/cacheImage"
import Carousel from "@brainhubeu/react-carousel"
import "@brainhubeu/react-carousel/lib/style.css"

const REACT_APP_API_URL_LIGHT = process.env.REACT_APP_API_URL_LIGHT

const useStyles = makeStyles((theme) => ({
  item: {
    padding: "2px",
    textAlign: "center",
  },
  content: {
    cursor: "pointer",
    backgroundColor: constants.color.primaryOp(0.1),
    height: "100%",
  },
  containerText: {
    padding: "10px",
  },
  imgModal: {
    display: "block",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}))

export default ({ items }) => {
  const { lang } = useContext(LanguagesContext);
  const classes = useStyles()
  const modal = useContext(ModalContext)
  const changeModal = (item) => {
    modal.setContent(<ModalItem item={item}/>, "500px")
  }

  return (
    <Container component="section" maxWidth={false}>
      <Grid container justify="center">
        {items.map((item, key) => {
          const text = translatorSimple({ translates: item.text, lang })
          const canModal = item.textModal.length > 0

          console.log(text.props.source, canModal,  item.link)
          return(
          <Grid key={key} item xs={12} sm={6} md={4} lg={3} className={classes.item}>
            <ItemLink link={(!canModal && item.link) ? item.link : null}>
              <MoveHoverTurbo changes={[{ style: "y", init: 0, hover: -5 }]} className={classes.content}
                              onClick={() => canModal ? changeModal(item) : null}>
                <BannerImg parallax={false} img={cacheImage((REACT_APP_API_URL_LIGHT + item.infoPhoto.url))} height={"300px"}/>
                <div className={({text} ? classes.containerText : "") + " margin-5 titleContrast"}>
                  {text}
                </div>
              </MoveHoverTurbo>
            </ItemLink>
          </Grid>
        )})}
      </Grid>
    </Container>
  )
}

const ItemLink = ({link, children}) => {
  if(link) 
    return <a href={link}>{children}</a>
  else return children
}


const ModalItem = ({ item }) => {
  const { getTranslate } = useContext(GenericTranslatesContext)
  const initialPhoto = item.infoPhoto
  const [activeImg, setActiveImg] = useState(initialPhoto)
  const classes = useStyles()

  useEffect(() => {
    setActiveImg(item.infoPhoto)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item])

  return <Grid container>
    <Grid item xs={6} className={classes.imgModal} style={{ position: "relative" }}>
      {item.gallery.length > 0 ?
        <SelectorImg item={item} activeImg={activeImg} setActiveImg={setActiveImg}/> :
        <BannerImg parallax={false} img={cacheImage((REACT_APP_API_URL_LIGHT + activeImg.url))} height={"500px"}/>}
    </Grid>
    <Grid item xs={12} md={6} style={{ padding: "15px", height: "500px", overflowY: "auto" }}
          className="titleMargin-0 titleContrast linkContrast">
      <Translator translates={item.textModal}/>

      {item.link && 
        <Button id="exception" color={"primary"} href={item.link} style={{ borderColor: "#8f7954" }}
                    className="textWhite" variant="outlined">
          <div style={{ color: "#8f7954" }}>
            {getTranslate("Entrar")}
          </div>
        </Button>
      }
    </Grid>
  </Grid>
}

const SelectorImg = ({ item, activeImg, setActiveImg }) => {
  const { infoPhoto, gallery } = item
  return <React.Fragment>
    <BannerImg parallax={false} img={cacheImage((REACT_APP_API_URL_LIGHT + activeImg.url))} height={"390px"}/>
    <div style={{
      display: "flex",
      width: "100%",
      justifyContent: "center",
      padding: "5px 0px",
      height: "110px"
    }}>
      <Carousel
        slidesPerPage={5}
        draggable={false}
        itemWidth={"100px"}
        rtl={true}
        offset={5}
      >

        {gallery.concat([infoPhoto]).map((img, key) => <div
          key={key}
          onClick={() => setActiveImg(img)}>
          <BannerImg parallax={false} img={cacheImage((REACT_APP_API_URL_LIGHT + img.url))} height={"100px"} style={{width: "100px", cursor: "pointer"}}/>
        </div>)}

      </Carousel>
    </div>
  </React.Fragment>
}
