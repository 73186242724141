import React, { useContext } from 'react';
import SectionsContext from "../wrappers/contexts/SectionsContext";
import Banner from "../dinamicComponents/Banner";
import TextRich from "../dinamicComponents/TextRich";
import FastGallery from "../dinamicComponents/FastGallery";
import UtilSpace from "../dinamicComponents/UtilSpace";
import RoomsPresentation from "../dinamicComponents/RoomsPresentation";
import SliderBanner from "../dinamicComponents/SliderBanner";
import Gallery from "../dinamicComponents/Gallery";
import ItemsGrid from "../dinamicComponents/ItemsGrid";
import { Redirect } from "react-router";
import SliderAdvantages from "../dinamicComponents/SliderAdvantages";
import Contact from "./Contact";
import ModalWrapper from "../wrappers/ModalWrapper";
import Loading from "./Loading";


export default ({ history }) => {
  const { sections, content } = useContext(SectionsContext);
  const urlName = history.location.pathname;
  const filter = sections.filter(section => ("/" + section.urlText) === urlName)[0];
  const section = filter ? filter : null;
  if (!section) {
    return <Redirect to={"/"}/>
  }
  const theContent = content[section.id];
  const { contact } = section;
  const filterContent = [];
  const itemsGrid = [];
  if (theContent) {
    theContent.forEach(cont => {
      if (cont.__component === "info.info-item") {
        if (itemsGrid.length <= 0) {
          filterContent.push(cont)
        }
        itemsGrid.push(cont)
      } else {
        filterContent.push(cont)
      }
    });
  }


  return <ModalWrapper>
    {filterContent.length <= 0 && <Loading/>}
    {filterContent.map((comp, key) => getComponent(comp, key, itemsGrid))}
    {contact && <Contact info={false} style={{ paddingTop: 0 }}/>}
  </ModalWrapper>
};


export const getComponent = (component, key, itemsGrid = []) => {
  switch (component.__component) {
    case "banner.banner":
      return <Banner component={component} key={key}/>;
    case "translate.text-rich":
      return <TextRich component={component} key={key}/>;
    case "gallery.fast-gallery":
      return <FastGallery component={component} key={key}/>;
    case "info.info-item":
      return <ItemsGrid key={key} items={itemsGrid}/>;
    case "util.space":
      return <UtilSpace component={component} key={key}/>;
    case "hotel.rooms-presentation":
      return <RoomsPresentation component={component} key={key}/>;
    case "slider-banner.slider-banner":
      return <SliderBanner component={component} key={key}/>;
    case "advantage.slider-of-advantage":
      return <SliderAdvantages component={component} key={key}/>;
    case "gallery.gallery":
      return <Gallery component={component} key={key}/>;
    default:
      return null;
  }
};
