import React, { useContext } from "react"
import { 
  Grid, Box,
  FormControl, Radio, 
  RadioGroup, 
  FormControlLabel, 
  FormLabel, makeStyles
} from "@material-ui/core"
import { translatorSimple } from "../../shared/Translator";
import LanguagesContext from "../../wrappers/contexts/LanguagesContext";
import GenericTranslatesContext from "../../wrappers/contexts/GenericTranslatesContext"

const useStyles = makeStyles(() => ({
  dishDescription: {
    fontSize: 13,
    padding: "0 15px 0 30px",
    color: "#666057"
  }
}))

const DishesBox = ({ translation, radioName, dishes, value, handlePerson, num }) => {
  const classes = useStyles();
  const { lang } = useContext(LanguagesContext);
  const { getTranslate } = useContext(GenericTranslatesContext)
  return(
    <Box component={Grid} item pb={3} xs={12} sm={6} md={4}>
      <FormControl component="fieldset">
        <FormLabel component="legend">{getTranslate(translation)}</FormLabel>
        <RadioGroup name={radioName} value={value} onChange={(event) => handlePerson(event, num)}>
          {dishes.map((dish, key) => {return(
            <div style={{ marginBottom: 10 }}>
              <FormControlLabel key={key} value={dish.id.toString()} control={<Radio color="primary"/>} label={translatorSimple({ translates: dish.name, lang })} style={{ marginBottom: "-10px" }} required/>
              {dish.description.length > 0 &&
                <div className={classes.dishDescription}>{translatorSimple({ translates: dish.description, lang })}</div>
              }
            </div>
          )})}
        </RadioGroup>
      </FormControl>
    </Box>
  )
}

export default DishesBox;