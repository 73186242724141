import React, { useContext, useEffect, useState, useRef } from "react"
import { Redirect } from "react-router-dom"
import ReCAPTCHA from "react-google-recaptcha"
import { addDays, subDays, format } from 'date-fns'
import DateFnsUtils from '@date-io/date-fns';
import { 
  Container, Grid, Box,
  FormControl, TextField,
  InputLabel, Input,
  FormHelperText, Snackbar,
  Button, makeStyles
} from "@material-ui/core"
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers';
import { Alert, AlertTitle } from '@material-ui/lab';

import { postRestaurantReservation } from "../../api/apiRequest"
import useTodaysMenu from "../hooks/requests/useTodaysMenu"

import Loading from "./Loading"
import GenericTranslatesContext from "../wrappers/contexts/GenericTranslatesContext"
import { checkRoomNumber } from "../utils/checkRoomNumber"
import Person from "./restaurantReservation/Person"

const REACT_APP_API_URL_LIGHT = process.env.REACT_APP_API_URL_LIGHT

const useStyles = makeStyles(() => ({
  bannerImg: {
    backgroundPosition: "center center", 
    // height: "300px",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    marginTop: "60px",
    flexGrow: 1,
    display: "flex",
    padding: 30,
    alignItems: "center",
    justifyContent: "center"
  },
  alert: {
    position: "fixed", 
    top: "90px", 
    zIndex: 20
  }
}))


export default () => {
  const classes = useStyles();
  const { getTranslate } = useContext(GenericTranslatesContext)

  // Hooks
  const { menu } = useTodaysMenu()

  const [reservation, setReservation] = useState({
    reservationName: "",
    email: "",
    room: "",
    date: new Date(),
    people: [],
    extraInformation: ""
  })
  const [disabled, setDisabled] = useState(true);
  const [alert, setAlert] = useState(false);
  const recaptchaRef = useRef();

  const handleInputChange = (event) => {
    if(event.target.name === "date"){
      if(!(subDays(new Date(), 1) < event.target.value && addDays(new Date(), 6) > event.target.value)){
        setAlert("dateError")
        return;
      }
    }
    setReservation({
      ...reservation,
      [event.target.name]: event.target.value
    })
  };

  const addPerson = () => {
    setReservation({
      ...reservation,
      people: [
        ...reservation.people,
        {
          name: "",
          starter: menu.starters[0].id.toString(),
          mainCourse: menu.mainCourses[0].id.toString(),
          dessert: menu.desserts[0].id.toString()
        }
      ]
    })
  }

  const handleCapcha = () => {
    if(recaptchaRef.current.getValue()){
      setDisabled(false)
    }
  }

  const handleClose = (reason) => {
    if (reason === 'clickaway') return;
    setAlert(false);
  };

  const getReservationObject = () => {
    const { reservationName, email, room, date, people, extraInformation } = reservation;

    let putJson = {
      name: reservationName, 
      email: email,
      wantedDate: format(date, 'yyyy-MM-dd'),
      people: []
    }
    
    if(extraInformation){putJson["additionalInformation"] = extraInformation}
    if(room){putJson["room"] = room}
    
    for(let i in people){
      putJson.people.push({
        name: people[i].name,
        starter: {
          id: parseInt(people[i].starter)
        },
        mainCourse: {
          id: parseInt(people[i].mainCourse)
        },
        dessert: {
          id: parseInt(people[i].dessert)
        }
      })
    }

    return putJson;
  }

  const submitData = (event) => {
    event.preventDefault();
    
    if(reservation.room && !checkRoomNumber(reservation.room)){
      setAlert("roomAlert")
      return;
    }

    postRestaurantReservation(getReservationObject())
      .then(({ data }) => {
        setDisabled(true);
        setAlert("success");
        recaptchaRef.current.reset();
      })
      .catch(() => console.log("ERRRRRROR"))
  }

  useEffect(() => {
    if (menu !== undefined && menu) addPerson()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menu])


  if (menu === undefined) {
    return <Loading/>
  }
  if (!menu) {
    return <Redirect to="/"/>
  }

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Snackbar autoHideDuration={600} style={{ height: "100vh", width: "100%" }} 
                open={alert} onClose={(reason) => handleClose(reason)}>
        {alert === "success" ?
          <Alert variant="filled" severity="success" onClose={(reason) => handleClose(reason)} className={classes.alert}>
            {getTranslate("Reserva finalizada correctamente")}!
          </Alert>
        :
        alert === "dateError" ?
          <Alert variant="filled" severity="error" onClose={(reason) => handleClose(reason)} className={classes.alert}>
            <AlertTitle>{getTranslate("Fecha no válida")}!</AlertTitle>
            {getTranslate("Solo se puede reservar con un margen de 7 días")}
          </Alert>
        : 
        alert === "roomAlert" ?
          <Alert variant="filled" severity="error" onClose={(reason) => handleClose(reason)} className={classes.alert}>
            <AlertTitle>{getTranslate("Número de habitación no válido")}!</AlertTitle>
            {getTranslate("Por favor, introduzca un número de habitación distinto")}
          </Alert>
        : null
        }
      </Snackbar>

      <div className={classes.bannerImg + " banner"} style={{ backgroundImage: `url(${REACT_APP_API_URL_LIGHT}${menu.banner.url})` }}>
        <Box maxWidth={"lg"} my={{xs: 4, sm: 6}} style={{ textAlign: "center" }}
            className="titleMargin-0 titleContrast linkContrast" component="section">
          <h1>{getTranslate("Hacer una reserva")}</h1>
          <p style={{color: "white"}}>{getTranslate("Primero, segundo, y postre, cada persona por solo")} {menu.price}€</p>
        </Box>
      </div>

      <Container maxWidth={false} style={{ marginBottom: "50px", padding: "40px 30px 50px", maxWidth: 1100 }}>
        

        <form onSubmit={submitData} autoComplete="off">
          <Grid container spacing={3} justify="center">
            <Grid item>
              <FormControl>
                <TextField name="reservationName" label={getTranslate("Nombre")}
                            value={reservation.reservationName} 
                            onChange={handleInputChange} 
                            required aria-describedby="nameHelper" />
                <FormHelperText id="nameHelper">{getTranslate("Reserva a nombre de")}</FormHelperText>
              </FormControl>
            </Grid>
          
            <Grid item>  
              <FormControl>
                <InputLabel htmlFor="email" required>{getTranslate("Email")}</InputLabel>
                <Input name="email" type="email" 
                        required value={reservation.email} 
                        onChange={handleInputChange}/>
              </FormControl>
            </Grid>

            <Grid item>
              <FormControl>
                <TextField name="room" label={getTranslate("Habitación")} aria-describedby="roomHelper" 
                        value={reservation.room} 
                        onChange={handleInputChange} />
                <FormHelperText id="roomHelper">{getTranslate("Opcional")}</FormHelperText>
              </FormControl>
            </Grid>

            <Grid item>
              <KeyboardDatePicker
                required
                disableToolbar
                variant="inline"
                format="dd/MM/yyyy"
                margin="normal"
                id="reservationDate"
                label={getTranslate("Fecha de reserva")}
                value={reservation.date}
                onChange={(date) => handleInputChange({target:  {name: "date", value: date }})}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </Grid>
          </Grid> 

          <Container maxWidth={false} style={{ margin: "30px 0 10px", padding: 0}}>
            {reservation.people && reservation.people.map((person, key) => {return(
              <Person key={key} num={key} {...{person, menu, reservation, setReservation}}
                      removable={reservation.people.length > 1 ? true : false}/>
            )})}
            <Button variant="contained" color="primary" style={{ borderRadius: "0 0 8px 8px"}} onClick={addPerson}>
              {getTranslate("Añadir persona")} <span style={{ fontSize: "25px", marginLeft: 4 }}>+</span>
            </Button>
          </Container>

          <div style={{ marginLeft: "auto", width: 600, maxWidth: "100%", marginTop: "-20px" }}>
            <FormControl style={{ width: "100%" }}>
              <TextField name="extraInformation" label={getTranslate("Información Adicional")}
                         value={reservation.extraInformation} aria-describedby="extraInfoHelper" 
                         onChange={handleInputChange} multiline rows={6} variant="filled" />
              <FormHelperText id="extraInfoHelper">{getTranslate("(alérgias, etc.)")}</FormHelperText>
            </FormControl>
          </div>

          <Box display="flex" flexDirection="column">
            <ReCAPTCHA
              style={{transform: "scale(0.83)", transformOrigin:"80% 0", marginLeft: "auto", display: "flex"}}
              ref={recaptchaRef}
              sitekey="6Lf3j-QZAAAAAKRjWHbACgcpo-mR_AIpL7-XLL5q"
              onChange={handleCapcha}
            />
            <Button disabled={disabled} variant="contained" color="primary" type="submit" style={{display: "flex", marginLeft: "auto"}}>
              {getTranslate("Reservar")}
            </Button>
          </Box>

        </form>
      </Container>
    </MuiPickersUtilsProvider>
  )
}


