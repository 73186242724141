import React, { useState, useEffect, useContext } from 'react';
import GenericTranslatesContext from "./contexts/GenericTranslatesContext";
import { getGeneralTranslations } from "../../api/apiRequest";
import LoadingControlContext from "./contexts/LoadingControlContext";
import { translatorSimple } from "../shared/Translator"
import LanguagesContext from "./contexts/LanguagesContext";


export default ({ children }) => {
  const { setError } = useContext(LoadingControlContext);
  const newGeneralTranslations = localStorage.getItem("ynaira-general-new-translations") ? JSON.parse(localStorage.getItem("ynaira-general-translations")) : null;
  const [genericTranslations, setGenericTranslations] = useState(newGeneralTranslations);
  const { lang } = useContext(LanguagesContext);

  useEffect(() => {
    getGeneralTranslations()
      .then(({ data }) => {
        const genericTranslations = {};
        data.forEach(item => {
          genericTranslations[item.name] = item.translate
        });
        setGenericTranslations(genericTranslations);
        localStorage.setItem("ynaira-general-new-translations", JSON.stringify(genericTranslations))
      })
      .catch(() => setError("No se ha podido encontrar traducciones generales"))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getTranslate = (name) => {
    if(genericTranslations){
      const algo = translatorSimple({ translates: genericTranslations[name], lang }) || name;
      return algo;
    }
    return name;
  };

  return (
    <GenericTranslatesContext.Provider value={{ getTranslate }} children={children}/>
  )
};
