import WifiIcon from "@material-ui/icons/Wifi";
import TvIcon from "@material-ui/icons/Tv";
import BluetoothIcon from "@material-ui/icons/Bluetooth";
import FreeBreakfastIcon from "@material-ui/icons/FreeBreakfast";
import LocalHotelIcon from "@material-ui/icons/LocalHotel";
import AccessibleIcon from "@material-ui/icons/Accessible";
import AddIcon from "@material-ui/icons/Add";
import AspectRatioIcon from "@material-ui/icons/AspectRatio";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import ImportContactsIcon from "@material-ui/icons/ImportContacts";
import LockIcon from "@material-ui/icons/Lock";
import PhotoSizeSelectActualIcon from "@material-ui/icons/PhotoSizeSelectActual";
import EuroIcon from '@material-ui/icons/Euro';
import SpaIcon from '@material-ui/icons/Spa';
import RoomServiceIcon from '@material-ui/icons/RoomService';
import LocalParkingIcon from '@material-ui/icons/LocalParking';
import DirectionsBikeIcon from '@material-ui/icons/DirectionsBike';
import CastIcon from '@material-ui/icons/Cast';
import SettingsRemoteIcon from '@material-ui/icons/SettingsRemote';
import LocalBarIcon from '@material-ui/icons/LocalBar';
import PoolIcon from '@material-ui/icons/Pool';
import WatchLaterOutlinedIcon from '@material-ui/icons/WatchLaterOutlined';
import LocalBarOutlinedIcon from '@material-ui/icons/LocalBarOutlined';
import WorkOutlineIcon from '@material-ui/icons/WorkOutline';
import SecurityIcon from '@material-ui/icons/Security';
import LibraryAddCheckIcon from '@material-ui/icons/LibraryAddCheck';
import React from "react";

export default (icon, size = "inherit") => {
  switch (icon) {
    case "Wifi":
      return <WifiIcon fontSize={size}/>;
    case "TV":
      return <TvIcon/>;
    case "Bluetooth":
      return <BluetoothIcon fontSize={size}/>;
    case "Cafe":
      return <FreeBreakfastIcon fontSize={size}/>;
    case "Cama":
      return <LocalHotelIcon fontSize={size}/>;
    case "Silla de ruedas":
      return <AccessibleIcon fontSize={size}/>;
    case "Espacio":
      return <AspectRatioIcon fontSize={size}/>;
    case "Personas":
      return <PeopleAltIcon fontSize={size}/>;
    case "Escritorio":
      return <ImportContactsIcon fontSize={size}/>;
    case "Caja fuerte":
      return <LockIcon fontSize={size}/>;
    case "Vistas":
      return <PhotoSizeSelectActualIcon fontSize={size}/>;
    case "Euro":
      return <EuroIcon fontSize={size}/>;
    case "Spa":
      return <SpaIcon fontSize={size}/>;
    case "Servicio habitacion":
      return <RoomServiceIcon fontSize={size}/>;
    case "Parking":
      return <LocalParkingIcon fontSize={size}/>;
    case "Desayuno":
      return <FreeBreakfastIcon fontSize={size}/>;
    case "Bicicleta":
      return <DirectionsBikeIcon fontSize={size}/>;
    case "Chromecast":
      return <CastIcon fontSize={size}/>;
    case "Remote":
      return <SettingsRemoteIcon fontSize={size}/>;
    case "Bar":
      return <LocalBarIcon fontSize={size}/>;
    case "Piscina":
      return <PoolIcon fontSize={size}/>;
    case "Reloj":
      return <WatchLaterOutlinedIcon fontSize={size}/>;
    case "Minibar":
      return <LocalBarOutlinedIcon fontSize={size}/>;
    case "Maleta":
      return <WorkOutlineIcon fontSize={size}/>;
    case "Seguridad":
      return <SecurityIcon fontSize={size}/>;
    case "Check":
      return <LibraryAddCheckIcon fontSize={size}/>;
    default:
      return <AddIcon fontSize={size}/>
  }
};
