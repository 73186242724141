import React from 'react';
import Grid from "@material-ui/core/Grid";
import logoColor from "../../media/logo/smallLogoColor.svg";

export default ({ error }) => {
  console.log(error);
  return (
    <Grid container justify="center" alignItems="center" style={{ minHeight: "100vh" }}>
      <div style={{padding: "20px"}}>
        <img width="200px" height="200px" src={logoColor} alt="logo Ynaira"/>
      </div>
      <div style={{textAlign: "center"}}>
        <div className="margin-0">
          <h4>Estimados clientes, ynairahotel.com está actualmente en mantenimiento.</h4>
          <h4>Volverá a estar disponible en la mayor brevedad posible</h4>
        </div>
        <h6>Gracias por su paciencia</h6>
      </div>
    </Grid>
  )
}