import React, { useState } from 'react';
import LoadingControlContext from "./contexts/LoadingControlContext";
import Maintenance from "../pages/Maintenance";
import Loading from "../pages/Loading";


export default ({ children }) => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  if (error) {
    return <Maintenance error={error}/>
  }

  if (loading) {
    return <LoadingControlContext.Provider value={{ error, setError, loading, setLoading }}>
      {children}
      <Loading/>
    </LoadingControlContext.Provider>
  }
  return (<LoadingControlContext.Provider value={{ error, setError, loading, setLoading }} children={children}/>)
};
