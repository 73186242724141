import React, { useContext, useEffect, useState } from 'react';
import { Button, Container } from "@material-ui/core";
import { getOneRoom } from "../../api/apiRequest";
import { NavLink, Redirect } from 'react-router-dom'
import GenericTranslatesContext from "../wrappers/contexts/GenericTranslatesContext";
import Loading from "./Loading";
import { getComponent } from "./Section";
import Features from "./room/Features";


export default ({ match, location }) => {
  const { getTranslate } = useContext(GenericTranslatesContext);
  const [room, setRoom] = useState(undefined);


  useEffect(() => {
    getOneRoom(match.params.id)
      .then(({ data }) => {
        if (data.length < 1) {
          return setRoom(null)
        }
        setRoom(data[0])
      })
      .catch(() => setRoom(null))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match]);

  console.log(match)
  console.log(location)
  console.log(room)


  if (room === undefined) {
    return <Loading/>
  }
  if (!room) {
    return <Redirect to="/"/>
  }

  return (
    <React.Fragment>
      {room.Content.map((comp, key) => getComponent(comp, key))}
      <Container maxWidth={"lg"} style={{marginBottom: "50px"}}>
        <NavLink to="/rooms" style={{ marginTop: "40px", marginBottom: "30px", marginRight: "10px" }}>
          <Button id="exception" color={"primary"} style={{ borderColor: "#8f7954" }}
                  className="textWhite" variant="outlined">
            <div style={{ color: "#8f7954" }}>
              {getTranslate("Volver")}
            </div>
          </Button>
        </NavLink>
        <a rel="noopener noreferrer" target="_blank" href={room.urlBooking} style={{ marginTop: "40px", marginBottom: "30px" }}>
          <Button id="exception" color={"primary"} style={{ borderColor: "#8f7954", minWidth: "150px" }}
                  className="textWhite" variant="contained">
            <div>
              {getTranslate("Reservar habitación")}
            </div>
          </Button>
        </a>
      </Container>
      <Features features={room.services}/>
    </React.Fragment>
  )
};
