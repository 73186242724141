import { useState, useEffect } from "react"
import { getSpaAppointments } from "../../../api/apiRequest";

const useSpaAppointments = () => {
  const [appointments, setAppointments] = useState();

  useEffect(() => {
    getSpaAppointments()
      .then(({ data }) => {
        if (data.length < 1) return setAppointments(null)
        setAppointments(data);
      })
      .catch(() => setAppointments(null))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    appointments
  }
  
}

export default useSpaAppointments;