import React, { useContext } from 'react';
import { Box, Button, Container, Grid, makeStyles } from "@material-ui/core";
import { Pannellum } from "pannellum-react";
import { useHistory } from "react-router-dom";
import Translator from "../shared/Translator";
import MoveHoverTurbo from "../shared/move/MoveHoverTurbo";
import GenericTranslatesContext from "../wrappers/contexts/GenericTranslatesContext";
import { Slider } from "./SliderBanner";
import BannerImg from "./shared/banner/BannerImg";
import constants from "../../styles/constants";

const REACT_APP_API_URL_LIGHT = process.env.REACT_APP_API_URL_LIGHT;

const useStyles = makeStyles({
  room: {
    borderBottom: "1px solid",
    paddingLeft: "10px",
    paddingBottom: "5px",
    paddingTop: "15px",
    cursor: "pointer"
  },
  title: {
    textAlign: "center"
  }
});

export default ({ component }) => {

  const { getTranslate } = useContext(GenericTranslatesContext);
  const history = useHistory();
  const classes = useStyles();


  return (
    <Container component="section" maxWidth={false}>
      <Grid container direction="row" justify="center">
        <Grid item xs={12} md={6} xl={8}>
          <Box style={{ height: "100%", minHeight: "400px" }}>
            <Img360 img={REACT_APP_API_URL_LIGHT + component.roomPhoto.url} roomsSlider={[...component.rooms]}
                    is360={component.is360}/>
          </Box>
        </Grid>
        <Grid item xs={12} md={6} xl={4} style={{backgroundColor: constants.color.background}}>
          <Grid container direction="column" justify="center" style={{ padding: "10px", height: "100%" }}>
            <div className={classes.title + " titleMargin-0 extraMargin-p titleContrast"}>
              <Translator translates={component.title}/>
            </div>
            <Grid container direction="column" justify="center">
              <Grid item>
                {component.rooms.map((rm, key) =>
                  <MoveHoverTurbo changes={[{ style: "paddingLeft", init: "7px", hover: "19px" }]}
                                  className={classes.room + " margin-0"}
                                  onClick={() => history.push("/room/" + rm.urlText)}
                                  key={key}><h4>{rm.name}</h4></MoveHoverTurbo>)}
              </Grid>
            </Grid>
            {component.urlLink && <Grid container justify="center" style={{ marginTop: "75px" }}>
              <a href={component.urlLink}>
                <Button id="exception" color={"primary"} style={{ borderColor: "#8f7954", minWidth: "150px" }}
                        variant="outlined">
                  <strong>{getTranslate("Reservar")}</strong>
                </Button>
              </a>
            </Grid>}
          </Grid>
        </Grid>
      </Grid>
    </Container>
  )
}


const Img360 = ({ img, is360, roomsSlider }) => {


  if (!is360) {
    if (roomsSlider.length > 0) {
      return <Slider component={{
                       percentOfHeight: 100,
                       exactHeight: "100%",
                       bannerInterval: 15,
                       justifyContent: "center",
                       alignItems: "center",
                       textAlign: "center",
                       banner: roomsSlider,
                       fullWindow: true
                     }}
                     attributes={{ fillParent: false, buttons: true }}/>
    } else {
      return <BannerImg height="100%" img={img} parallax={false}/>
    }
  }

  return (
    <Pannellum
      width="100%"
      height="400px"
      image={img}
      haov={360}
      vaov={180}
      vOffset={0}
      pitch={-20}
      yaw={0}
      hfov={90}
      maxHfov={90}
      minHfov={90}
      autoLoad
      orientationOnByDefault={false}
      draggable
      showControls
      showFullscreenCtrl
      showZoomCtrl={false}
      hotspotDebug={false}
      autoRotate={-7}>

    </Pannellum>
  )
};
