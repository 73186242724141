import React, { useContext, useState } from 'react';
import makeStyles from "@material-ui/core/styles/makeStyles";
import LanguagesContext from "../wrappers/contexts/LanguagesContext";
import DropDown from "./nav/DropDown";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

const useStyles = makeStyles(() => ({
  lang: {
    fontSize: "16px",
    marginRight: "20px",
    marginLeft: "20px",
    paddingBottom: "3px",
    textTransform: "uppercase",
    cursor: "pointer",
  }
}));

export default () => {
  const { languages, changeLang, lang } = useContext(LanguagesContext);
  const [visibleMenu, setVisibleMenu] = useState(false)
  const classes = useStyles(lang);
  let theLanguages = [];
  languages.forEach((lang) => {
    theLanguages.push({name: lang.lang, onClick: () => changeLang(lang.lang), link: "#"})
  });
  return (
    <React.Fragment>
      <div className={classes.lang} onMouseEnter={() => setVisibleMenu(true)} onMouseLeave={() => setVisibleMenu(false)}>
        {lang}
        <ArrowDropDownIcon fontSize={"small"}/>
        <DropDown visible={visibleMenu} direction="column" items={theLanguages} />
      </div>
    </React.Fragment>
  )
};
