import React, { useState } from "react"
import { Container, makeStyles } from "@material-ui/core"
import AwesomeSlider from "react-awesome-slider"
import withAutoplay from "react-awesome-slider/dist/autoplay"
import "./shared/sliderBanner/slider.css"
import ContentBanner from "./shared/ContentBanner"
import cacheImage from "../utils/cacheImage"

const AutoplaySlider = withAutoplay(AwesomeSlider)

const REACT_APP_API_URL_LIGHT = process.env.REACT_APP_API_URL_LIGHT

export default ({ component }) => {

  const banners = component.banner

  if (banners.length <= 0) {
    return null
  }
  return <Slider component={component}/>
};

const useStyles = makeStyles((theme) => ({
  boxContent: {
    position: "absolute",
    width: "100%",
    left: 0,
    top: 0,
  },
  bannerContent: {
    minHeight: "inherit",
    padding: "24px 0",
    [theme.breakpoints.down("sm")]: {
      padding: "75px 0",
    },
  },
  bannerContentNoBreak: {
    minHeight: "inherit",
    padding: "24px 0",
  },
}))

export const Slider = ({ component, unitOfHeight = "vh", attributes = {} }) => {
  const { fullWindow } = component

  if (fullWindow) {
    return <BannerSlider component={component} unitOfHeight={unitOfHeight} attributes={attributes}/>
  } else {
    return <Container maxWidth={false}>
      <BannerSlider component={component} unitOfHeight={unitOfHeight} attributes={attributes}/>
    </Container>
  }
}

const BannerSlider = ({ component, attributes = {} }) => {
  const {
    percentOfHeight,
    bannerInterval,
    justifyContent,
    alignItems,
    textAlign,
    banner,
    fullWindow,
    exactHeight,
  } = component
  const classes = useStyles()

  const getRandomImagesFromBanners = (banners) => {
    let randomArray = [];
    for(let i in banners){
      if(!Array.isArray(banners[i].photo)) {
        randomArray.push(banners[i].photo)
      } else if (banners[i].photo.length > 1){
        randomArray.push(banners[i].photo[Math.floor(Math.random() * banners[i].photo.length)])
      } else {
        randomArray.push( banners[i].photo[0])
      }
    }
    return randomArray
  }

  const [ randomImages ] = useState(getRandomImagesFromBanners(banner))

  const theAttributes = {
    bullets: false,
    // fillParent: fullWindow,
    cancelOnInteraction: false,
    interval: (bannerInterval ? (bannerInterval * 1000) : 15000),
    buttons: !fullWindow,
    ...attributes,
  }

  const optionsBoxText = {
    alignItems,
    justifyContent,
    textAlign,
    border: true,
    titleContrast: false,
  }

  const height = exactHeight || ((percentOfHeight - (window.screen.width > 500 ? 0 : 7)) + "vh")

  if (banner.length < 1) return null;

  return (
    <section id={"algo"} style={{ minHeight: height, height: 0, display: "flex" }}>
      <AutoplaySlider className={"algo"} {...theAttributes} play={true} style={{ fillParent: "cover" }}>
        {banner.map((banner, key) => {
          if (randomImages[key]) {
            cacheImage((REACT_APP_API_URL_LIGHT + randomImages[key].url))
            return (
              <div key={key} data-src={REACT_APP_API_URL_LIGHT + randomImages[key].url}>
                <div style={{ minHeight: height }} className={classes.boxContent}>
                  {(banner.text && banner.text.length > 0) &&
                  <Container maxWidth={false} style={{ minHeight: height }}>
                    <ContentBanner jump={!fullWindow} options={optionsBoxText} content={banner.text}
                                   className={fullWindow ? classes.bannerContent : classes.bannerContentNoBreak}/>
                  </Container>}
                </div>
              </div>
            )
          }
          return null
        })}
      </AutoplaySlider>
    </section>
  )
}
