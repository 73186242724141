import React, { useContext } from "react"
import { Grid, Box,TextField, IconButton, makeStyles } from "@material-ui/core"
import CloseIcon from '@material-ui/icons/Close';
import GenericTranslatesContext from "../../wrappers/contexts/GenericTranslatesContext"
import DishesBox from "./DishesBox"

const useStyles = makeStyles(() => ({
  personContainer: {
    padding: "20px 40px 10px",
    backgroundColor: "#dfd1be",
    borderBottom: "solid 1px #8F7954",
    position: "relative"
  },
  closeButton: {
    position: "absolute",
    top: "20px",
    right: "40px"
  }
}))


const Person = ({ person, menu, num, removable, reservation, setReservation }) => {
  const classes = useStyles();
  const { getTranslate } = useContext(GenericTranslatesContext)

  const handlePerson = (event, num) => {
    let modifiedPeople = reservation.people;
    modifiedPeople[num][event.target.name] = event.target.value;
    setReservation({
      ...reservation,
      people: modifiedPeople
    })
  };

  const removePerson = (num) => {
    let modifiedPeople = reservation.people;
    modifiedPeople.splice(num, 1)
    setReservation({
      ...reservation,
      people: modifiedPeople
    })
  }

  return(
    <Grid container className={classes.personContainer} style={(num === 0) ? {borderTop: "solid 1px #8F7954"} : {}}>

      <Box component={Grid} item pb={4} xs={12}>
        <TextField name="name" label={getTranslate("Nombre")}
                    value={person.name} required 
                    onChange={(event) => handlePerson(event, num)}/>
      </Box>

      <DishesBox translation="Primer plato" radioName="starter" dishes={menu.starters} value={person.starter} {...{handlePerson, num}}/>

      <DishesBox translation="Plato principal" radioName="mainCourse" dishes={menu.mainCourses} value={person.mainCourse} {...{handlePerson, num}}/>

      <DishesBox translation="Dessert" radioName="dessert" dishes={menu.desserts} value={person.dessert} {...{handlePerson, num}}/>

      <Box display={removable ? "flex" : "none"} component={IconButton} aria-label="close" color="inherit" className={classes.closeButton}
            onClick={() => removePerson(num)}>
        <CloseIcon fontSize="inherit" />
      </Box>

    </Grid>
  )
}

export default Person;