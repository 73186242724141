import React, { useContext, useState, useEffect } from "react"
import { addDays, addMinutes, format, subMinutes } from 'date-fns'
import CustomDatePicker from "../../shared/CustomDatePicker"
import GenericTranslatesContext from "../../wrappers/contexts/GenericTranslatesContext"



const SpaDatePicker = ({selected, handleInputChange, settings, appointments, getNextAbleHour, getTimeFromString, getWeekdaySchedule}) => {
  const { getTranslate } = useContext(GenericTranslatesContext)
  const [ curDateAppointments, setCurDateAppointments ] = useState(null)
  
  function getDateAppointments(date) {
    if(appointments)
      return appointments.filter(aptm => format(Date.parse(aptm.startingTime), "yyyy-MM-dd") === format(date, "yyyy-MM-dd"))
    return []
  }

  function handleDateTimeChange(date) {
    handleInputChange({target:  {name: "date", value: date }})
  }

  useEffect(() => {
    setCurDateAppointments(getDateAppointments(selected))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected])

  function filterTime(dateTime){
    let time = format(dateTime, "kk:mm:ss")
    let countSimAptms = 0;
    for(let appointment of curDateAppointments){
      if(time >= format(Date.parse(appointment.startingTime), "kk:mm:ss") && time < format(Date.parse(appointment.endingTime), "kk:mm:ss"))
        countSimAptms++
      if(countSimAptms >= settings.simultaneusAppointments) return false
    }
    return true
  }

  function filterDate(date){
    return getWeekdaySchedule(date).opens
  }

  function minHourCheckToday(){
    const openingHour = getTimeFromString(getWeekdaySchedule(selected).OpeningHour)
    if(format(selected, "yyyy-MM-dd") === format(new Date(), "yyyy-MM-dd")){
      if(addMinutes(new Date(), 60) < openingHour)
        return openingHour
      else 
        return addMinutes(new Date(), 60);
        // return new Date()
    } else return openingHour
  }

  return(
    <CustomDatePicker 
      title={getTranslate("Fecha de la cita")} 
      curDate={selected}
      handleOnChange={handleDateTimeChange}
      timeIntervals={settings.defaultTime}
      minDate={getNextAbleHour()} 
      maxDate={addDays(new Date(), 6)}
      minTime={minHourCheckToday()} 
      maxTime={subMinutes(getTimeFromString(getWeekdaySchedule(selected).ClosingHour), settings.defaultTime)}
      filterTime={filterTime} 
      filterDate={filterDate}
      label={getTranslate("Hora")}
      />
  )
}

export default SpaDatePicker