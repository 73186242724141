import React, { useEffect, useState, useContext } from "react"
import { getOneArticle } from "../../api/apiRequest"
import Loading from "./Loading"
import Translator, { translatorSimple } from "../shared/Translator"
import LanguagesContext from "../wrappers/contexts/LanguagesContext";
import { Redirect } from "react-router-dom"
import Moment from 'moment';
import { Container, makeStyles } from "@material-ui/core"
import Box from "@material-ui/core/Box";
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const REACT_APP_API_URL_LIGHT = process.env.REACT_APP_API_URL_LIGHT

const theme = createMuiTheme();
theme.typography.h1 = {
  fontSize: "50px",
  [theme.breakpoints.up('sm')]: {
    fontSize: '90px',
  }
};

const useStyles = makeStyles(({
  bannerImg: {
    backgroundPosition: "center center", 
    height: "100%",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    minHeight: "600px",
    flexGrow: 1,
    display: "flex",
    padding: 30,
    alignItems: "center",
    justifyContent: "center"
  },
  title: {
    color:"#fff",
    lineHeight: "1.2",
    fontWeight: 400,
    fontFamily: "PlayfairDisplay"
  }
}))

export default ({ match }) => {
  const { lang } = useContext(LanguagesContext);
  const classes = useStyles();
  const [article, setArticle] = useState(undefined)
  const id = match.params.id

  useEffect(() => {
    getOneArticle(match.params.id)
      .then(({ data }) => {
        if (data.length < 1) {
          return setArticle(null)
        }
        setArticle(data[0])
      })
      .catch(() => setArticle(null))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])


  if (article === undefined) {
    return <Loading/>
  }
  if (!article) {
    return <Redirect to="/"/>
  }
  

  const { image, title, content, publishedAt } = article

  const contentTranslate = translatorSimple({ translates: content.translate, lang })

  return (
    
    <Container maxWidth={false} style={{padding: 0, margin: 0}}>
      <div className={classes.bannerImg + " banner"} style={{ backgroundImage:"url(" + (REACT_APP_API_URL_LIGHT + image.url) + ")" }}>
        <ThemeProvider theme={theme}>
          <Typography variant="h1" className={classes.title}>
            <Translator translates={title}/>
          </Typography>
        </ThemeProvider>
      </div>

      <Box my={8} mx="auto" px={3} className="titleMargin-0 titleContrast linkContrast" style={{ maxWidth: "950px" }} component={"div"}>
        <div className="grayColor">
          {contentTranslate ? contentTranslate : content.translate[0].content}
        </div>
        <p style={{ fontFamily: "PlayfairDisplay" }}>
          <time>{Moment(publishedAt).format('MM-DD-yyyy')}</time>
        </p>
      </Box>
    </Container>
  )
}
