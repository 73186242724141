import { useState, useEffect } from "react"
import { getSpaSettings } from "../../../api/apiRequest";

const useSpaSettings = () => {
  const [settings, setSettings] = useState();

  useEffect(() => {
    getSpaSettings()
      .then(({ data }) => {
        if (data.length < 1) return setSettings(null)
        setSettings(data);
      })
      .catch(() => setSettings(null))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    settings
  }
  
}

export default useSpaSettings;