import React from 'react';
import 'pure-react-carousel/dist/react-carousel.es.css';
import Grid from "@material-ui/core/Grid";
import getFeatureIcon from "../utils/getFeatureIcon";
import Translator from "../shared/Translator";
import Container from "@material-ui/core/Container";

export default ({ component }) => {

  const services = component.services_and_advantages;

  if (services.length <= 0) {
    return null
  }
  return <TheSlider component={component}/>;
};


const TheSlider = ({ component }) => {
  let services = component.services_and_advantages;
  return (
    <React.Fragment>
      <div style={{ textAlign: "center", marginBottom: "20px" }} className="titleMargin-0 titleContrast">
        <Translator translates={component.title}/>
      </div>

      <Grid container justify="center" alignItems="center">
        {services.map((service, key) =>
          <Grid key={key} item xs={12} md={4} style={{ padding: "20px" }}>
            <Grid container direction="column" alignItems="center" justify="center" style={{ height: "100%", textAlign: "center", whiteSpace: "nowrap" }}
                  className="titleContrast">
              <div>
                {getFeatureIcon(service.icon, "large")}
              </div>
              <h4>
                <Translator translates={service.text}/>
              </h4>
              {service.subtitle && <div style={{ textAlign: "right", opacity: 0.7, fontSize: "11px" }} className="grayColor">
                <Translator translates={service.subtitle}/>
              </div>}
            </Grid>
          </Grid>
        )}
      </Grid>
      {(component.footer && component.footer.length > 0) && <Container maxWidth={false} style={{ textAlign: "right", opacity: 0.7, fontSize: "13px" }} className="grayColor">
        {<Translator translates={component.title}/> | ""}
      </Container>}
    </React.Fragment>
  )
};
