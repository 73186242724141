import { createMuiTheme } from '@material-ui/core/styles';
import constants from './constants';

export default createMuiTheme({
  palette: {
    primary: {
      main: constants.color.primary,
      // contrastText: constants.color.white,
    },
    secondary: {
      main: constants.color.gray200,
      // contrastText: constants.color.primary,
    },
    background: {
      default: constants.color.background
    },
    text: {
      primary: constants.color.primary,
      // secondary: constants.color.white,
    }
  },
  shape: {
    borderRadius: 0
  },
  typography: {
    fontFamily: constants.fontFamily,
  },
});

