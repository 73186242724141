import ReactMarkdown from "react-markdown";
import React, { useContext } from "react";
import LanguagesContext from "../wrappers/contexts/LanguagesContext";

const REACT_APP_API_URL_LIGHT = process.env.REACT_APP_API_URL_LIGHT;

export default ({translates, lang = useContext(LanguagesContext).lang}) => {
  return translatorSimple({ translates: translates, lang})
}

export const translatorSimple = ({ translates, lang }) => {
  if (translates) {
    const correctTranslate = translates.filter(trans => (trans.language ? trans.language.lang : trans.lang) === lang)[0];
    const engTranslate = translates.filter(trans => (trans.language ? trans.language.lang : trans.lang) === "en")[0];

    if (correctTranslate) {
      if(correctTranslate.text)
        return correctTranslate.text
      else if (correctTranslate.content)
        return <ReactMarkdown source={correctTranslate.content} transformImageUri={(path) => REACT_APP_API_URL_LIGHT + path}/>

    } else if (engTranslate) {
      if(engTranslate.text)
        return engTranslate.text
      else if (engTranslate.content)
        return <ReactMarkdown source={engTranslate.content} transformImageUri={(path) => REACT_APP_API_URL_LIGHT + path}/>
        
    } else if (translates[0] && translates[0].text) {
      return translates[0].text
    }
    
  }
  return null;
}

