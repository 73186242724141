import request from "./request";

export const getLanguages = () => request.get("/languages");
export const getGeneralTranslations = () => request.get("/general-translations");
export const getSectionsNoContent = () => request.get("/sections/not-content");
export const getSectionContent = (id) => request.get("/sections/content/" + id);
export const getGeneralData = () => request.get("/general-information");
export const getOffers = () => request.get("/offers?active=true");
export const getOneOffer = (urlText) => request.get("/offers?active=true&urlText=" + urlText);
export const getOneRoom = (urlKey) => request.get("/rooms?urlText=" + urlKey);
export const getArticlesNoContent = () => request.get("/articles/no-content");
export const getOneArticle = (urlText) => request.get("/articles?url=" + urlText);
export const getTodaysMenu = () => request.get("/todays-menu/complex");
export const postRestaurantReservation = (reservation) => request.post("/reservations", reservation);
export const getSpaSettings = () => request.get("/spa-settings");
export const getSpaAppointments = () => request.get("/spa-appointments/future");
export const postSpaAppointment = (appointment) => request.post("/spa-appointments", appointment);
export const postConsultation = (consultation) => request.post("/consultations", consultation);
