import React, { useEffect, useState } from "react"
import posed from "react-pose"
import { makeStyles } from "@material-ui/core/styles"
import { Grid } from "@material-ui/core"
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined"
import IconButton from "@material-ui/core/IconButton"
import constants from "../../../styles/constants"

const MoveDiv = posed.div({
  exit: {
    opacity: 0,
    applyAtEnd: { display: "none" },
    transition: { duration: 200 },
  },
  enter: {
    opacity: 1,
    applyAtStart: { display: "block" },
    transition: { duration: 200 },
  },
})

const useStyles = makeStyles(() => ({
  container: {
    width: "100%",
    height: "100vh",
    position: "fixed",
    right: 0,
    left: 0,
  },
  boxContainer: {
    backgroundColor: constants.color.whiteOp(0.60),
    height: "100%",
  },
  overModal: {
    overflowY: "auto",
    overflowX: "hidden",
    border: ("1px solid"),
    boxSizing: "content-box"
  },
  modal: {
    width: "90vw",
    minWidth: "300px",
    maxWidth: "1200px",
    backgroundColor: (constants.color.background),
  },
}))

export default ({ changeModal, children, height }) => {
  const [isEscapeable, setEscapeable] = useState(true)
  const [prevChildren, setPrevChildren] = useState(children)
  const classes = useStyles()
  const doNothing = () => null
  useEffect(() => {
    if (children) {
      setPrevChildren(children)
    }
  }, [children])

  const tryClose = () => isEscapeable ? changeModal() : doNothing()
  return (
    <MoveDiv style={{ zIndex: 4000 }} className={classes.container} pose={children ? "enter" : "exit"}>
      <Grid container direction="column" alignItems="center" justify="center" className={classes.boxContainer}
            onClick={tryClose}>
        <div className={classes.overModal} style={{ maxHeight: height }}
             onMouseOver={() => setEscapeable(false)}
             onMouseMove={() => setEscapeable(false)}
             onMouseLeave={() => setEscapeable(true)}
             onMouseDown={() => setEscapeable(false)}
             onMouseEnter={() => setEscapeable(false)}
             onClick={() => setEscapeable(false)}>
          <Grid container className={classes.modal}>
            <Grid item container xs={12} justify="flex-end">
              <div style={{ position: "absolute", padding: "10px", paddingRight: "15px" }}>
                <IconButton onClick={changeModal} aria-label="update" style={{padding: "5px"}}>
                  <CloseOutlinedIcon/>
                </IconButton>
              </div>
            </Grid>
            {prevChildren}
          </Grid>
        </div>
      </Grid>
    </MoveDiv>
  )
};
