import React, { useCallback, useEffect, useState } from "react"
import Gallery from "react-photo-gallery"
import Carousel, { Modal, ModalGateway } from "react-images"
import cacheImage from "../utils/cacheImage"

const REACT_APP_API_URL_LIGHT = process.env.REACT_APP_API_URL_LIGHT

export default ({ component }) => {
  const [photos, setPhotos] = useState([])
  const [currentImage, setCurrentImage] = useState(0)
  const [viewerIsOpen, setViewerIsOpen] = useState(false)

  useEffect(() => {
    const filteredPhotos = []

    const addPhoto = (photo) => {
      filteredPhotos.push({
        src: cacheImage((REACT_APP_API_URL_LIGHT + photo.photo.url)),
        width: photo.photo.width,
        height: photo.photo.height,
        caption: photo.caption,
      })
    }
    component.photo.forEach(photo => addPhoto(photo))
    const newOrder = filteredPhotos.sort(() => Math.random() - 0.5)
    setPhotos(newOrder)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  const openLightbox = useCallback((event, some) => {
    const { index } = some
    setCurrentImage(index)
    setViewerIsOpen(true)
  }, [])

  const closeLightbox = () => {
    setCurrentImage(0)
    setViewerIsOpen(false)
  }

  return (
    <React.Fragment>
      <Gallery photos={photos} onClick={openLightbox}/>

      <ModalGateway>
        {viewerIsOpen ? (
          <Modal styles={{ zIndex: 200000 }} onClose={closeLightbox}>
            <Carousel
              styles={{ zIndex: 200000 }}
              currentIndex={currentImage}
              isModal={true}
              views={photos.map(x => ({
                ...x,
                srcset: x.srcSet,
                caption: x.caption,
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </React.Fragment>
  )
};
