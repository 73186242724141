import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import defaultWall from "./../../../../media/walls/default.png"
import "./banner.css"

const useStyles = makeStyles(({
  bannerImg: {
    backgroundAttachment: (props => props.parallax ? "fixed" : "inherit"),
    backgroundPosition: "center bottom",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundImage: props => "url(" + (props.img || defaultWall) + ")",
    minHeight: props => props.height || "50vh",
    flexGrow: 1
  }
}));

export default ({ img, height, parallax = true, ...props }) => {
  const classes = useStyles({img, height, parallax});

  return (
    <div className={classes.bannerImg + " banner"} {...props}/>
  )
};
