import React, { useEffect, useState } from "react"
import { getOffers } from "../../api/apiRequest"
import Loading from "./Loading"
import { NavLink, Redirect } from "react-router-dom"
import { Container, makeStyles } from "@material-ui/core"
import Grid from "@material-ui/core/Grid"
import BannerImg from "../dinamicComponents/shared/banner/BannerImg"
import Translator from "../shared/Translator"
import getWindowSize from "../utils/getWindowSize"

const REACT_APP_API_URL_LIGHT = process.env.REACT_APP_API_URL_LIGHT

const useStyles = makeStyles((theme) => ({
  textBox: {
    backgroundColor: "#dfd1be",
  },
}))

export default ({ match }) => {
  const classes = useStyles()
  const [offers, setOffers] = useState(undefined)
  const { width } = getWindowSize()

  useEffect(() => {
    getOffers()
      .then(({ data }) => {
        if (data.length < 1) {
          return setOffers(null)
        }
        setOffers(data)
      })
      .catch(() => setOffers(null))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (offers === undefined) {
    return <Loading/>
  }
  if (!offers) {
    return <Redirect to="/"/>
  }

  return (
    <React.Fragment>
      <Container maxWidth={false} style={{ marginBottom: "50px", paddingTop: "100px" }}>
        {offers.map((offer, key) => <NavLink key={key} to={("/offer/" + offer.urlText)}><Grid container
                                                                                              style={{ padding: "10px" }}>
          {(key % 2 !== 0 && width > 960) &&
          <Grid item xs={12} md={6} lg={4} className={classes.textBox + " titleMargin-0 extraMargin-p titleContrast"}
                style={{ padding: "30px" }}>
            <Translator translates={offer.content.translate}/>
          </Grid>}
          <Grid item xs={12} md={6} lg={8}>
            <BannerImg img={REACT_APP_API_URL_LIGHT + offer.img[(offer.img.length - 1)].url} parallax={false} height={"400px"}
                       style={{ backgroundPosition: "center center", height: "100%" }}/>
          </Grid>
          {(key % 2 === 0 && width > 960) &&
          <Grid item xs={12} md={6} lg={4} className={classes.textBox + " titleMargin-0 extraMargin-p titleContrast"}
                style={{ padding: "30px" }}>
            <Translator translates={offer.content.translate}/>
          </Grid>}
          {width <= 960 &&
          <Grid item xs={12} md={6} lg={4} className={classes.textBox + " titleMargin-0 extraMargin-p titleContrast"}
                style={{ padding: "30px" }}>
            <Translator translates={offer.content.translate}/>
          </Grid>}

        </Grid></NavLink>)}
      </Container>
    </React.Fragment>
  )
}
