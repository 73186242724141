import React, { useContext, useState } from "react"
import makeStyles from "@material-ui/core/styles/makeStyles"
import constants from "../../../styles/constants"
import { NavLink } from "react-router-dom"
import logo from "../../../media/logo/completeLogoColor.svg"
import { Box } from "@material-ui/core"
import Grid from "@material-ui/core/Grid"
import PhoneIcon from "@material-ui/icons/Phone"
import GeneralDataContext from "../../wrappers/contexts/GeneralDataContext"
import Languages from "../Languages"
import MenuIcon from "@material-ui/icons/Menu"
import CloseIcon from "@material-ui/icons/Close"
import IconButton from "@material-ui/core/IconButton"
import posed from "react-pose"
import useScrollTrigger from "@material-ui/core/useScrollTrigger"
import MoveHoverTurbo from "../move/MoveHoverTurbo"
import FacebookIcon from "@material-ui/icons/Facebook"
import InstagramIcon from "@material-ui/icons/Instagram"
import SectionsContext from "../../wrappers/contexts/SectionsContext"
import Translator from "../Translator"
import getWindowSize from "../../utils/getWindowSize"
import GenericTranslatesContext from "../../wrappers/contexts/GenericTranslatesContext"
import logoSimple from "./../../../media/logo/textLogoColor.svg"
import DropDown from "./DropDown"
import LanguagesContext from "../../wrappers/contexts/LanguagesContext"
import { useLocation } from "react-router"


const Logo = posed.div({
  complete: {
    height: "auto",
    boxShadow: "0 .5rem 1rem rgba(0,0,0,.15)",
  },
  small: {
    height: "100%",
    boxShadow: "0 .5rem 1rem rgba(0,0,0,0)",
  },
})

const MenuMove = posed.div({
  open: {
    width: "100%",
  },
  close: {
    width: 0,
  },
})

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: "#c0ab8c",
    backdropFilter: "blur(10px)",
    position: "fixed",
    width: "100%",
    zIndex: 1100,
    minHeight: "60px",
    paddingLeft: "24px",
    [theme.breakpoints.down("xs")]: {
      paddingLeft: "16px",
    },
  },
  logo: {
    backgroundColor: constants.color.background,
    position: "absolute",
  },
  inherit: {
    height: "inherit",
  },
  icons: {
    height: "20px",
    width: "20px",
    margin: "5px",
  },
  boxMenuIcon: {
    padding: "5px 0",
    margin: "0 5px",
  },
}))


export default () => {
  const classes = useStyles()
  const windowSize = getWindowSize()
  const [menu, setMenu] = useState(false)

  const isMenuOpen = menu && (windowSize.width < 1920)

  return (
    <React.Fragment>
      <Grid container direction="row" justify="center" className={classes.appBar}>
        <Grid item xs={12}>
          <LogoResponsive className={classes.logo} onClick={() => setMenu(false)} windowSize={windowSize}/>
          <Grid container justify="flex-end" alignItems="center" direction="row" className="textWhite"
                style={{ height: "100%" }}>
            <SectionsRow/>
            <PhoneResponsive/>
            <IconsSocial/>
            <Grid className={classes.boxMenuIcon}>
              <ButtonMenu open={menu} onClick={() => setMenu(!menu)}/>
            </Grid>
            <Booking/>
            <Box display={{ xs: "none", xl: "flex" }}>
              <Languages/>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <SectionsColumn isOpen={isMenuOpen} close={() => setMenu(false)}/>
    </React.Fragment>
  )
};

const Booking = () => {
  const { getTranslate } = useContext(GenericTranslatesContext)
  const { lang } = useContext(LanguagesContext);
  console.log('lang', lang)
  const contact = useContext(GeneralDataContext)
  return (
    <Box display={{ xs: "none", md: "block" }} style={{ height: "100%", padding: "0px 0px 0px 15px" }}>
      <a href={(contact.bookingUrl || "").replace("{{lang}}", lang || "es")} rel="noopener noreferrer" target="_blank"
         style={{
           height: "60%", width: "100%",
           padding: "5px 15px 0", fontSize: "16px",
           backgroundColor: constants.color.extremColor,
           display: 'flex', justifyContent: 'center', alignItems: 'center',
           textTransform: "uppercase"
        }}
      >
        <MoveHoverTurbo changes={[{
          style: "borderBottom",
          init: "1px solid transparent",
          hover: "1px solid white",
        }, { style: "y", init: 0, hover: -5 }]}
                        speed={150}>
          {getTranslate("Reservar")}
        </MoveHoverTurbo>
      </a>

      <a href={contact.checkInUrl} rel="noopener noreferrer" target="_blank"
         style={{
           height: "40%", width: "100%",
           padding: "0px 20px", fontSize: "11px",
           backgroundColor: constants.color.extremColor2,
           display: 'flex', justifyContent: 'center', alignItems: 'center',
           textTransform: "uppercase", cursor: "pointer"
         }}
      >
        <MoveHoverTurbo changes={[{
          style: "borderBottom",
          init: "1px solid transparent",
          hover: "1px solid white",
        }, { style: "y", init: 0, hover: -5 }]} speed={150}>
          {getTranslate("Checkin")}
        </MoveHoverTurbo>
      </a>
    </Box>
  )
}

const ButtonMenu = ({ onClick, open }) => {
  return (
    <React.Fragment>
      <Box display={{ xs: "block", xl: "none" }}>
        <MoveHoverTurbo changes={[{ style: "scale", init: 1, hover: 1.2 }]} speed={150}>
          <IconButton aria-label="menu" color="secondary" onClick={onClick}>
            {open ? <CloseIcon/> : <MenuIcon/>}
          </IconButton>
        </MoveHoverTurbo>
      </Box>
      <Box display={{ xs: "none", xl: "block" }} style={{ margin: "0 15px 1px 15px" }}>
        |
      </Box>
    </React.Fragment>
  )
}

const LogoResponsive = ({ className, onClick, windowSize }) => {
  const trigger = useScrollTrigger({ disableHysteresis: true, threshold: 0 })
  const { pathname } = useLocation()
  const classes = useStyles()

  const isSmall = windowSize.width < 500 || trigger || pathname === "/contact"

  return (
    <React.Fragment>
      <NavLink to="/" onClick={onClick}>
        <div style={{ height: "100%", display: "flex", alignItems: "center", position: "absolute" }}>
          <img src={logoSimple} height={"40px"} width={"40px"} alt="" style={{ position: "absolute" }}/>
        </div>
        <Logo pose={isSmall ? "small" : "complete"} className={className}>
          <Box display={{ xs: "none", xl: "block" }} className={classes.inherit}>
            <MoveHoverTurbo changes={[{ style: "y", init: 0, hover: -5 }]} className={classes.inherit}
                            style={{ padding: "0px 5px" }}>
              {isSmall ? <img style={{ height: "120px", maxWidth: "120px", maxHeight: "100%" }} src={logoSimple}
                              alt="Ynaira Logo"/> :
                <img style={{
                  height: "120px",
                  width: "120px",
                  maxHeight: "100%",
                  marginTop: "15px",
                  marginBottom: "15px",
                }} src={logo} alt="Ynaira Logo"/>
              }
            </MoveHoverTurbo>
          </Box>
          <Box display={{ xs: "block", xl: "none" }} className={classes.inherit}>
            <MoveHoverTurbo changes={[{ style: "y", init: 0, hover: -5 }]} className={classes.inherit}
                            style={{ padding: "0px 5px" }}>
              {isSmall ? <img style={{ height: "90px", maxWidth: "90px", maxHeight: "100%" }} src={logoSimple}
                              alt="Ynaira Logo"/> :
                <img
                  style={{ height: "90px", width: "90px", maxHeight: "100%", marginTop: "15px", marginBottom: "15px" }}
                  src={logo} alt="Ynaira Logo"/>
              }
            </MoveHoverTurbo>
          </Box>
        </Logo>
      </NavLink>
    </React.Fragment>
  )
}

const SectionsRow = () => {
  const [drop, setDrop] = useState(null)
  const { sections } = useContext(SectionsContext)
  const { getTranslate } = useContext(GenericTranslatesContext)
  const orderSections = sections.sort((a, b) => a.smallMenuOrder - b.smallMenuOrder).filter(sec => sec.smallMenuOrder > 0)
  return (
    <React.Fragment>
      {orderSections.map((section, key) => {
        return <MoveHoverTurbo key={key} changes={[{
          style: "borderBottom",
          init: "1px solid transparent",
          hover: "1px solid white",
        }]} speed={50} style={{ margin: "0 8px" }}>
          <NavLink to={"/" + section.urlText} className="textWhite">
            <Box display={{ xs: "none", xl: "flex" }}
                 onMouseEnter={() => setDrop(section.urlText)} onMouseLeave={() => setDrop(null)}
                 style={{ fontSize: "16px", textTransform: "uppercase" }}>
              <Translator translates={section.titleMenu}/>
            </Box>
          </NavLink>
          <DropDown visible={section.urlText === drop} direction="column" items={section.dropDowns}
                    style={{ paddingLeft: "10px" }}/>
        </MoveHoverTurbo>
      })}
      <MoveHoverTurbo changes={[{ style: "borderBottom", init: "1px solid transparent", hover: "1px solid white" }]}
                      speed={50} style={{ margin: "0 8px" }}>
        <NavLink to={"/offers"}>
          <Box display={{ xs: "none", xl: "flex" }}
               style={{ fontSize: "16px", textTransform: "uppercase" }}>
            {getTranslate("Ofertas")}
          </Box>
        </NavLink>
      </MoveHoverTurbo>
      <MoveHoverTurbo changes={[{ style: "borderBottom", init: "1px solid transparent", hover: "1px solid white" }]}
                      speed={50} style={{ margin: "0 8px" }}>
        <NavLink to={"/blog"}>
          <Box display={{ xs: "none", xl: "flex" }}
               style={{ fontSize: "16px", textTransform: "uppercase" }}>
            {getTranslate("Blog")}
          </Box>
        </NavLink>
      </MoveHoverTurbo>
      <MoveHoverTurbo changes={[{ style: "borderBottom", init: "1px solid transparent", hover: "1px solid white" }]}
                      speed={50} style={{ margin: "0 8px" }}>
        <NavLink to={"/contact"}>
          <Box display={{ xs: "none", xl: "flex" }}
               style={{ fontSize: "16px", textTransform: "uppercase" }}>
            {getTranslate("Contáctanos")}
          </Box>
        </NavLink>
      </MoveHoverTurbo>
    </React.Fragment>
  )
}

const PhoneResponsive = () => {
  const classes = useStyles()
  const generalData = useContext(GeneralDataContext)
  return (
    <MoveHoverTurbo changes={[{ style: "scale", init: 1, hover: 1 }]} speed={100}>
      <Box component="a" display={{ xs: "none", sm: "flex", xl: "none" }}
           href={"tel:+34" + generalData.phone} style={{ fontSize: "16px" }}>
        <PhoneIcon className={classes.icons}/>
      </Box>
    </MoveHoverTurbo>
  )
}


const IconsSocial = () => {
  const generalData = React.useContext(GeneralDataContext)
  const classes = useStyles()
  const social = [
    { href: generalData.facebookUrl, icon: FacebookIcon },
    { href: generalData.instagramUrl, icon: InstagramIcon },
  ]
  return (
    <React.Fragment>
      {social.map((net, key) => {
        const Icon = net.icon
        return <MoveHoverTurbo key={key} changes={[{ style: "scale", init: 1, hover: 1 }]} speed={100}>
          <Box component="a" rel="noopener noreferrer" target="_blank" href={net.href}
               display={{ xs: "none", sm: "flex", xl: "none" }}
               style={{ fontSize: "16px" }}>
            <Icon className={classes.icons}/>
          </Box>
        </MoveHoverTurbo>
      })}
    </React.Fragment>
  )
}

const useStylesMenu = makeStyles(theme => ({
  container: {
    backgroundColor: "#c0ab8c",
    backdropFilter: "blur(10px)",
    position: "fixed",
    top: 0,
    right: 0,
    maxWidth: "300px",
    justifyContent: "center",
    flexDirection: "column",
    height: "100vh",
    overflow: "hidden",
    display: "flex",
    zIndex: 1099,
    [theme.breakpoints.down("xs")]: {
      maxWidth: "100%",
    },
  },
  link: {
    padding: "10px 30px",
    minWidth: "280px",
    backgroundColor: constants.color.whiteOp(0.10),
  },
}))

const SectionsColumn = ({ close, isOpen }) => {
  const classes = useStylesMenu()
  const { sections } = useContext(SectionsContext)
  const { getTranslate } = useContext(GenericTranslatesContext)
  const { languages, changeLang } = useContext(LanguagesContext)
  const orderSections = sections.sort((a, b) => a.bigMenuOrder - b.bigMenuOrder).filter(sec => sec.bigMenuOrder > 0)
  return (
    <MenuMove pose={isOpen ? "open" : "close"} className={classes.container + " textWhite"}>
      {orderSections.map((section, key) =>
        <NavLink key={key} to={section.urlText}>
          <MoveHoverTurbo onClick={close} changes={[{
            style: "backgroundColor",
            init: constants.color.whiteOp(0),
            hover: constants.color.whiteOp(0.20),
          }]} speed={100}>
            <Box style={{ fontSize: "24px", textTransform: "uppercase" }} className={classes.link}>
              <Translator translates={section.titleMenu}/>
            </Box>
          </MoveHoverTurbo>
        </NavLink>,
      )}
      <NavLink to={"/offers"}>
        <MoveHoverTurbo onClick={close} changes={[{
          style: "backgroundColor",
          init: constants.color.whiteOp(0),
          hover: constants.color.whiteOp(0.20),
        }]} speed={100}>
          <Box style={{ fontSize: "24px", textTransform: "uppercase" }} className={classes.link}>
            {getTranslate("Ofertas")}
          </Box>
        </MoveHoverTurbo>
      </NavLink>
      <NavLink to={"/blog"}>
        <MoveHoverTurbo onClick={close} changes={[{
          style: "backgroundColor",
          init: constants.color.whiteOp(0),
          hover: constants.color.whiteOp(0.20),
        }]} speed={100}>
          <Box style={{ fontSize: "24px", textTransform: "uppercase" }} className={classes.link}>
            {getTranslate("Blog")}
          </Box>
        </MoveHoverTurbo>
      </NavLink>
      <NavLink to={"/contact"}>
        <MoveHoverTurbo onClick={close} changes={[{
          style: "backgroundColor",
          init: constants.color.whiteOp(0),
          hover: constants.color.whiteOp(0.20),
        }]} speed={100}>
          <Box style={{ fontSize: "24px", textTransform: "uppercase" }} className={classes.link}>
            {getTranslate("Contáctanos")}
          </Box>
        </MoveHoverTurbo>
      </NavLink>
      <div style={{ display: "flex", padding: "10px 30px", minWidth: "250px" }}>
        {languages.map((lang, key) => <div onClick={() => changeLang(lang.lang)} style={{
          padding: "0px 15px 0px 0px",
          textTransform: "uppercase",
          fontSize: "20px",
          cursor: "pointer",
        }} key={key}>
          {lang.lang}
        </div>)}
      </div>
    </MenuMove>
  )
}


