import React, { useState } from 'react';
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";
import constants from "../../../styles/constants";
import { NavLink } from "react-router-dom";
import Translator from "../Translator";

const useStyles = makeStyles(theme => ({
  drop: {
    position: "absolute",
    paddingTop: "10px",
    color: constants.color.primary,
  },
  container: {
    backgroundColor: constants.color.background
  },
  item: {
    "&:hover": {
      backgroundColor: constants.color.primaryOp(0.2),
    }
  }
}));

export default ({ visible, direction = "column", items = [], style }) => {
  const [visibleMenu, setVisibleMenu] = useState(false);
  const classes = useStyles();
  return (
    <div onMouseEnter={() => setVisibleMenu(true)} onMouseLeave={() => setVisibleMenu(false)} className={classes.drop}
         style={{ display: (((visible && items.length > 0) || visibleMenu) ? "block" : "none"), ...style }}>
      <Grid container direction={direction} justify="center" alignItems={"center"} className={classes.container}>
        {items.map((item, key) => <NavLink to={item.link} key={key} style={{ width: "100%" }}>
          <div className={classes.item} onClick={item.onClick}>
            <div style={{ padding: "10px 20px", textTransform: "uppercase" }}>
              {item.name ? item.name : <Translator translates={item.title}/>}
            </div>
          </div>
        </NavLink>)}
      </Grid>
    </div>
  )
}