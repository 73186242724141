
export function checkRoomNumber(roomNumber) {
  return roomNumbers.includes(roomNumber)
}

const roomNumbers = [
    "101",
    "102",
    "103",
    "104",
    "105",
    "106",
    "107",
    "108",
    "109",
    "110",
    "201",
    "202",
    "203",
    "204",
    "205",
    "206",
    "207",
    "208",
    "209",
    "210",
    "A01",
    "A02",
    "A03",
    "A04",
    "A05"
]